@font-face {
    font-family: "KoaraWildBold";
    src: url('../fonts/koara/koara-wild-bold.woff2') format('woff2'), url('../fonts/koara/koara-wild-bold.woff') format('woff');
}

@font-face {
    font-family: "KoaraRegular";
    src: url('../fonts/koara/koara-regular.woff2') format('woff2'), url('../fonts/koara/koara-regular.woff') format('woff');
}

@mixin koara {
    font-family: "KoaraRegular";
    font-weight: 400;
}

@mixin koara-bold {
    font-family: "KoaraWildBold";
    font-weight: 700;
}

@mixin brandon-regular {
    font-family: "brandon-grotesque", sans-serif;
    font-weight: 400;
    font-style: normal;
}

@mixin brandon-italic {
    font-family: "brandon-grotesque", sans-serif;
    font-weight: 400;
    font-style: italic;
}

@mixin brandon-bold {
    font-family: "brandon-grotesque", sans-serif;
    font-weight: 700;
    font-style: normal;
}

@mixin brandon-bold-italic {
    font-family: "brandon-grotesque", sans-serif;
    font-weight: 700;
    font-style: italic;
}