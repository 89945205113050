// Core elements are included on multiple pages, they can be included within the main stylesheet
html {
    margin: 0;
    padding: 0;
    background-color: $quarternarycolor;
    &.translated-ltr, &.translated-rtl {
        header.fixed_header {
            top: 39px !important;
        }
    }
}

body {
    margin: 0;
    padding: 0;
    background-color: $quarternarycolor;
    font-size: 20px;
    @include brandon-regular;
    color: $primarycolor;
}

// Define core elements sizes and styles 
h1 {
    color: $primarycolor;
    font-size: 42px;
    @include koara-bold;
}

h2 {
    color: $primarycolor;
    font-size: 32px;
    @include koara-bold;
}

h3 {
    color: $primarycolor;
    font-size: 24px;
    @include koara-bold;
}

a {
    color: $linkcolor;
    text-decoration: none;
    &:hover {
        color: $linkcolor;
        text-decoration: underline;
        cursor: pointer;
    }
}

b, strong {
    font-weight: 700 !important;
    color: $primarycolor;
}

dl {
    &.dl_horizontal {
        dt{
            float: left;
            width: 200px;
            overflow: hidden;
            clear:left;
            text-align:left;
            text-overflow:ellipsis;
            white-space:nowrap
        }
        dd {
            margin-left:180px;
        }
    }
}

.container {
    @media(min-width: 1400px){
        max-width: 1400px;
    }
    @media(min-width: 768px) and (max-width: 991px){
        max-width: 728px;
    }
    &.container_thin {
        @media(min-width: 992px){
            max-width: 938px;
        }
    }
    &.add_padding {
        padding-top: 50px;
        @media(max-width: 991px){
            padding-top: 30px;
        }
    }
}

.btn {
    font-weight: bold;
    &.btn-primary {
        background-color: $primarycolor;
        border-color: $primarycolor;
        color: $quarternarycolor;
        &:hover, &:active, &:visited {
            background-color: $primarycolor;
            border-color: $primarycolor;
            color: $quarternarycolor;
        }
    }
    
    &.btn-secondary {
        background-color: $secondarycolor;
        border-color: $secondarycolor;
        color: $quarternarycolor;
        &:hover, &:active, &:visited {
            background-color: $secondarycolor;
            border-color: $secondarycolor;
            color: $quarternarycolor;
        }
    }
    
    &.btn-tertiary {
        background-color: $tertiarycolor;
        border-color: $tertiarycolor;
        color: $quarternarycolor;
        &:hover, &:active, &:visited {
            background-color: $tertiarycolor;
            border-color: $tertiarycolor;
            color: $quarternarycolor;
        }
    }
    
    &.btn-quarternary {
        background-color: $quarternarycolor;
        border-color: $quarternarycolor;
        color: $primarycolor;
        &:hover, &:active, &:visited {
            background-color: $quarternarycolor;
            border-color: $quarternarycolor;
            color: $primarycolor;
        }
    }
}


// forms start here

.success-back {
    margin-bottom: 35px;
    @include koara;
    font-size: 16px;
    color: #511B4A;
}

.application_fieldset {
    border-radius: 15px;
    padding: 30px;
    background: #FBF2E8;
    margin-bottom: 16px;

    &.application_fieldset_b2 {
        display: flex;
        row-gap: 16px;
        flex-direction: column;
    }

    .bereavement_occured_extra {
        display: flex;
        row-gap: 16px;
        flex-direction: column;
    }

    @media(max-width: 767px){
        padding: 22px;
    }

    .spacing {
        height: 30px;
    }

    legend {
        margin-bottom: 15px;
        @include koara-bold;
        font-size: 24px;

        @media(max-width: 767px){
            font-size: 18px;
        }

        @media(max-width: 375px){
            font-size: 24px;
        }
    }

    .legend {
        margin-bottom: 15px;
        @include koara-bold;
        font-size: 24px;

        @media(max-width: 767px){
            font-size: 18px;
        }
    }

    .comment {
        font-size: 16px;
        line-height: 22px;

        em {
            font-style: normal;
            color: $secondarycolor;
        }

        p.yellow {
            color: $highlightcolor;
            a {
                color: $highlightcolor;
                &:hover {
                    color: $highlightcolor;
                }
            }
        }
    }

    .counter_group {
        .counter_item {
            .counter_form {
                display: none;
            }

            .counter_switch {
                display: block;
            }

            .counter_cancel {
                text-align: right;
                button {
                    border: none;
                    box-shadow: none;
                    background: transparent;
                    font-size: 16px;
                    text-decoration: underline;
                    color: #511A4A;
                    @media(max-width: 767px){
                        font-size: 14px;
                    }
                    @media(max-width: 375px){
                        font-size: 16px;
                    }
                }
            }

            &--open {
                .counter_form {
                    display: block;
                }
                .counter_switch {
                    display: none;
                }
            }
        }
    }

    .counter_form {
        margin-bottom: 20px;
    }

    .counter {
        margin-bottom: 10px;
        font-weight: 700;
        font-size: 14px;
    }

    .success-subtitle {
        margin-bottom: 20px;
        @include koara-bold;
        font-size: 24px;
        color: #511A4A;
    }

    .success-content {
        @include brandon-regular;
        font-weight: 600px;
        font-size: 24px;
        color: #511A4A;
    }

}

.field_wrapper {
    &.required-field, &.required {
        label:after {
            content: ' *';
            color: red;
        }
    }
    .invalid-feedback {
        display: block;
        color: red;
    }
}

.available {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 100%;

    @media(max-width:559px){
        align-items: start;
        flex-direction: column;
    }
}

.dow-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media(max-width:559px){
        margin-bottom: 20px;
    }
}

.dow-columns {
    display: flex;
    @media(max-width:559px){
        flex-direction: column;
    }
}

.dow {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 700;
    @media(max-width:559px){
        font-size: 18px;
    }
}

.calendar_day {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
    label {
        margin-top: 10px;
        font-size: 10px;
    }

    @media(max-width:559px){
        flex-direction: row;
        align-items: center;
        label {
            margin-left: 10px;
            margin-top: 5px;
            font-size: 18px;
        }
    }

}



.fields {
    display: flex;
    flex-wrap: wrap;
    row-gap: 16px;
    column-gap: 16px;

    h3 {
        font-size: 20px;
        margin-bottom: 0;
        @media(max-width: 991px){
            font-size: 16px;
        }
        @media(max-width: 767px){
            font-size: 14px;
        }
    }

    .application_field {
        width: calc(50% - 8px);

        @media(max-width: 375px){
            width: 100%;
        }

        &.full_width {
            width: 100%;
        }

        &.thin_field {
            width: calc(25% - 12px);
            @media(max-width: 375px){
                width: calc(50% - 8px);
            }
        }

        &.field-id {
            display: none;
        }

        .input-group-text {
            background: $primarycolor;
            border: 1px solid $primarycolor;
            color: $quarternarycolor;
            @media(max-width: 767px){
                font-size: 15px;
            }
        }

        &.type-textarea,
        &.type-checkboxinput,
        &.type-checkboxselectmultiple {
            width: 100%;
        }

        &.type-radioselect,
        &.type-checkboxselectmultiple {
            label.form-label {
                font-family: "KoaraWildBold";
                font-weight: 700;
                font-size: 16px;

                @media(max-width: 767px){
                    font-size: 14px;
                }
                @media(max-width: 375px){
                    font-size: 12px;
                }

            }
            label.form-check-label::after {
                content: '';
            }
        }

        // Vacancies Form //

        &.field-eo_gender,
        &.field-eo_as_at_birth,
        &.field-ro_attachment,
        &.it_email,
        &.it_text,
        &.field-eo_sexual_reason {
            width: 100%;
        }

        // Bereavement Form //

        &.field-who_you_are,
        &.field-supporter_has_consent,
        &.field-bereavement_occured,
        &.field-bereavement_occured_period,
        &.field-bereavement_died_month,
        &.field-bereavement_died_year,
        &.field-bereavement_died_who,
        &.field-think_and_act_clearly,
        &.field-acceptance_of_grief,
        &.field-overwhelming_feelings,
        &.field-worry_of_loosing_control {
            width: 100%;
        }

        &.field-ro_unspent {
            margin-right: calc(50% + 8px);
            label.form-label {
                @include brandon-bold;
                font-weight: 600;
                font-size: 16px;
            }
        }

        // Hospital Care Referral Form //

        &.field-previous_treatment,
        &.field-hospital_infection,
        &.field-aware_smoking_policy,
        &.field-clinical_correspondence,
        &.field-advanced_decisions {
            width: 100%;
        }

        &.type-checkboxselectmultiple {
            .form-label + div {
                display: flex;
                flex-wrap: wrap;
                .form-check {
                    width: 33.33%;
                }

                @media(max-width: 767px){
                    flex-direction: column;
                    .form-check {
                        width: 100%;
                    } 
                }

            }
        }

        // Lottery

        &.field-how_often_play,
        &.field-collection {
            .form-check {
                .form-check-label {
                    font-weight: 400;
                }
                &:first-child {
                    display: none;
                }
            }
        }

        &.field-direct_debit {
            .form-check {
                .form-check-label {
                    font-weight: 400;
                }
            }
        }

        &.form-lotteryentrynewmemberform {
            &.field-direct_debit {
                display: flex;
                justify-content: center;
            }
        }

        &.field-account_name,
        &.field-sort_code,
        &.field-account_number,
        &.field-institution,
        &.field-initials_payment,
        &.field-initials_terms {
            margin-right: calc(50% + 8px);

            @media(max-width: 767px){
                margin-right: 0;
                width: 100%;
            }

            label.form-label {
                @include brandon-bold;
                font-weight: 600;
                font-size: 16px;
            }
        }

        &.field-collection,
        &.field-how_often_play {
            width: 100%;

            label.form-label {
                @include brandon-bold;
                font-weight: 600;
                font-size: 16px;
            }
        }

        &.field-terms_long_term_support,
        &.field-terms_paid_fundraiser,
        &.field-terms_rules_explained,
        &.field-confirm_paid_fundraiser,
        &.field-confirm_cost_profit,
        &.field-confirm_direct_debit,
        &.field-confirm_arrangements {
            width: 100%;
            label.form-label {
                @include brandon-bold;
                font-weight: 600;
                font-size: 16px;
            }
            .form-check {
                .form-check-label {
                    font-weight: 400;
                }
                &:first-child {
                    display: none;
                }
            }
        }

        &.form-LotteryEntrySubmitForm {
            &.field-email {
                @media(max-width: 767px){
                    width: 100%;
                }
            }
        }

        label {
            font-weight: 600;
            font-size: 16px;
            @media(max-width: 767px){
                font-size: 14px;
            }
            @media(max-width: 375px){
                font-size: 16px;
            }
        }

        input[type=text],
        input[type=email],
        input[type=number],
        input[type=date],
        select {
            height: 46px;
            @media(max-width: 767px){
                height: 36px;
            }
            @media(max-width: 375px){
                font-size: 46px;
            }
        }

        input[type=text],
        input[type=email],
        input[type=number],
        input[type=date],
        textarea,
        select {
            background-color: #FBF2E8;
            border-color: #511A4A;
            font-size: 16px;
            @media(max-width: 767px){
                font-size: 14px;
            }
            @media(max-width: 375px){
                font-size: 16px;
            }
        }

        textarea {
            height: 140px;
        }

        input[type=checkbox] {
            border-color: #511A4A;
            background-color: #FBF2E8;
        }
        input[type=checkbox]:checked {
            background-color: #511A4A;
        }

        input[type=radio]:checked {
            background-color: #511A4A;
        }

        input[type=file]::file-selector-button {
            margin-top: -4px;
            margin-bottom: -4px;
            margin-left: -10px;
            border-radius: 0.375rem;
            background-color: #511A4A;
            color: #F3E7D9;
            font-size: 15px;
        }
        select {
            font-size: 15px;
        }
    }
}

.forgot_link {
    margin-top: 20px;
}

.submit {
    text-align: right;
    .btn {
        font-size: 18px;
        padding: 15px 18px;
    }
}

.back {
    @include koara-bold;
    margin-bottom: 35px;
    font-size: 16px;
}

.keep_in_touch {
    color: $quarternarycolor;
    &.contact_block {
        background: $primarycolor;
        padding: 30px;
        margin-bottom: 20px;
        border-radius: 8px;
    }

    &.kit_donate {
        color: $primarycolor;
        .field_wrapper {
            .form-check {
                input {
                    border-color: $primarycolor;
                    background-color: transparent;
                }
                label {
                    color: $primarycolor;
                }
            }
        }
    }

    .kit_header, .kit_footer {
        font-size: 14px;
        line-height: 1.5em;
        &.kit_header {
            margin-bottom: 16px;
        }
    }
    .field_wrapper {
        margin-bottom: 15px;
        .form-check {
            margin: 0;
            font-size: 18px;
            input {
                border-color: $quarternarycolor;
                background-color: $primarycolor;
                &:checked {
                    background-color: $primarycolor;
                }
            }
            label {
                font-size: 14px;
            }
        }
    }
}

#header {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 999;
    transition: background-color 0.5s ease, color 0.5s ease;
    @media(max-width: 991px){
        transition: unset;
    }
    &.purple_header {
        transition: none;
        .container {
            max-width: 100%;
        }
    }
    &.fixed_header {
        position: fixed;
        background-color: $primarycolor;
        @media(max-width: 991px){
            background: none;
        }
    }
    &.fixed_header, &.purple_header {
        background-color: $primarycolor;
        @media(max-width: 991px){
            position: absolute;
        }
        .container {
            .navigation {
                .nav-button {
                    background-color: $quarternarycolor;
                    color: $primarycolor;
                }
            }
        }
    }

    &.nav_open {
        @media(max-width: 991px){
            background: $highlightcolor;
            position: fixed;
            top:0;
            left: 0;
            right: 0;
            bottom: 0;
            .container {
                .logo {
                    .white_logo {
                        display: none;
                    }
                    .purple_logo {
                        display: block;
                    }
                }
                .navigation {
                    display: block;
                    .nav_inner {
                        display: block;
                    }
                }
                .close_header {
                    display: block;
                }
            }
        }
    }
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media(max-width: 991px){
            flex-direction: column;
            align-items: flex-start;
        }
        .logo {
            padding: 15px 0 18px;
            @media(max-width: 991px){
                padding-top: 28px;
            }
            @media(max-width: 767px){
                padding-top: 35px;
            }
            .purple_logo {
                display: none;
            }
            img {
                height: 70px;
                width: auto;
                @media(max-width: 991px){
                    height: 92px;
                }
                @media(max-width: 767px){
                    height: 55px;
                }
            }
        }
        .navigation {
            display: flex;
            justify-content: right;
            flex-direction: column;
            align-items: flex-end;
            row-gap: 10px;
            @media(max-width: 991px){
                display: none;
                width: 100%;
            }
            .nav_inner {
                display: flex;
                justify-content: left;
                column-gap: 30px;
                align-items: center;
                @include koara;
                @media(max-width: 1199px){
                    column-gap: 18px;
                }
                > ul {
                    display: flex;
                    justify-content: left;
                    flex-direction: row;
                    column-gap: 30px;
                    margin: 0;
                    padding: 0;
                    @media(max-width: 1199px){
                        column-gap: 18px;
                    }
                    @media(max-width: 991px){
                        column-gap: 30px;
                        row-gap: 20px;
                        padding: 20px 0;
                        width: 100%;
                        flex-wrap: wrap;
                    }
                    li {
                        list-style: none;
                        width: 100%;
                        &.icon {
                            line-height: 27px;
                            display: flex;
                            align-items: center;
                            @media(max-width: 991px){
                                width:auto;
                            }
                            a {
                                @media(max-width: 991px){
                                    &::after {
                                        content: "";
                                    }
                                }
                            }
                        }
                        a {
                            font-size: 18px;
                            color: #fff;
                            display: flex;
                            justify-content: space-between;
                            @media(max-width: 991px){
                                color: $primarycolor;
                                &::after{
                                    font-family: "Font Awesome 6 Pro";
                                    content: "\f0da";
                                    font-weight: 900;
                                }
                            }
                        }
                    }
                }
                .nav-button {
                    font-size: 18px;
                    border-radius: 10px;
                    background-color: $primarycolor;
                    color: $quarternarycolor;
                    transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
                    @media(max-width: 991px){
                        width: 100%;
                    }
                    &.secondary {
                        background-color: $tertiarycolor;
                        color: $primarycolor;
                    }
                }
                .dropdown-menu {
                    background: $quarternarycolor;
                    a {
                        color: $primarycolor;
                    }
                    .active { 
                        a {
                            background: $quarternarycolor;
                        }
                    }
                }

            }
            .translate {
                width: 140px;
                @media(max-width: 991px){
                    width: 100%;
                    margin-top: 30px;
                    display: flex;
                    justify-content: right;
                }
                #google_translate_element {
                    font-size: 0;
                    height: 25px;
                    width: 140px;
                    span {
                        display: none;
                    }
                    .goog-te-gadget {
                        font-size: 0;
                    }
                    .goog-te-combo {
                        position: relative;
                        display: block;
                        top: -4px;
                        height: 25px;
                        width: 100%;
                        border-radius: 6px;
                        background-color: transparent;
                        color: #fff;
                        border: none !important;
                        font-size: 16px;
                        padding: 0;
                        margin: 0;
                        @include koara;
                        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
                        background-repeat: no-repeat;
                        background-position: right center;
                        background-size: 20px 15px;
                        border-radius: 0.25rem;
                        appearance: none;
                        @media(max-width: 991px){
                            color: $primarycolor;
                            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23511B4A' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
                        }
                        option {
                            color: $primarycolor;
                        }
                    }
                    option {
                        font-weight: 400;
                    }
                }
            }
        }
        .close_header {
            display: none;
            position: absolute;
            top: 35px;
            right: 12px;
            font-size: 36px;
            color: $primarycolor;
            line-height: 1em;
        }
    }
}



#minicart {
    position: absolute;
    top: 103px;
    right: calc(50% - 685px);
    z-index: 999;
    width: 380px;
    background-color: #ffffff;
    padding: 25px;
    display: none;

    @media(max-width: 1400px){
        right: calc(50% - 555px);
    }

    @media(max-width: 1199px){
        right: calc(50% - 468px);
    }

    @media(max-width: 991px){
        right: 20px;
        top: 20px;
    }

    .close {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 20px;
        line-height: 12px;
        border: none;
        background: transparent;
        box-shadow: none;
        text-align: center;
    }

    .title {
        margin-bottom: 15px;
        font-weight: 700;
        font-size: 22px;
        line-height: 19px;
    }

    .product {
        margin-bottom: 15px;
        display: flex;
        align-items: center;

        .image {
            width: 80px;
            height: auto;
        }

        .label {
            margin-left: 20px;
            font-size: 20px;
        }

    }

    .btn {
        margin-top: 15px;
        margin-left: auto;
        margin-right: auto;
        width: 190px;
    }

}

#social {
    background: $secondarycolor;
    color: $quarternarycolor;
    padding: 25px 0 18px;
    .container {
        display: flex;
        justify-content: space-between;
        align-items: top;
        column-gap: 120px;
        flex-direction: row;
        @media(min-width: 992px){
            max-width: 1316px;
        }
        @media(max-width: 991px){
            column-gap: 20px;
        }
        @media(max-width: 767px){
            flex-direction: column;
            row-gap: 30px;
            align-items: center;
        }
    }
    .social_column {
        text-align: center;
        &.newsletter {
            width: 420px;
            @media(max-width: 991px){
                width: 220px;
            }
            @media(max-width: 767px){
                width: 285px;
            }
            .newsletter_form {
                display: flex;
                align-items: left;
                flex-grow: 1;
                .email {
                    border: 0;
                    padding: 0;
                    background: transparent;
                    border: 1px solid $quarternarycolor;
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                    color: $quarternarycolor;
                    padding: 10px;
                    width: calc(100% - 88px);
                    font-size: 18px;
                    @media(max-width: 991px){
                        font-size: 9px;
                        width: calc(100% - 48px);
                    }
                    @media(max-width: 767px){
                        font-size: 18px;
                    }
                    &::placeholder {
                        opacity: 1;
                        color: $quarternarycolor;
                    }
                }
                .btn {
                    border-radius: 0;
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                    padding: 10px 16px;
                    width: 88px;
                    font-size: 18px;
                    @media(max-width: 991px){
                        width: 48px;
                        font-size: 9px;
                        padding: 6px 9px;
                    }
                    @media(max-width: 767px){
                        width: 48px;
                        font-size: 9px;
                        padding: 6px 9px;
                    }
                }
            }
        }
        &.social_media {
            width: 250px;
            @media(max-width: 991px){
                width: 130px;
            }
            @media(max-width: 767px){
                width: 285px;
            }
            .icons {
                padding-top: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                column-gap: 30px;
                @media(max-width: 991px){
                    column-gap: 13px;
                }
                .icon {
                    font-size: 24px;
                    color: $quarternarycolor;
                    @media(max-width: 991px){
                        font-size: 16px;
                    }
                }
            }
        }
        &.get_in_touch {
            width: 420px;
            @media(max-width: 991px){
                width: 220px;
            }
            @media(max-width: 767px){
                width: 285px;
            }
        }
        h3 {
            @include koara-bold;
            font-size: 28px;
            margin-bottom: 10px;
            color: $quarternarycolor;
            @media(max-width: 991px){
                font-size: 16px;
            }
            @media(max-width: 767px){
                font-size: 20px;
            }
            a {
                color: $quarternarycolor;
            }
        }
        p {
            font-size: 18px;
            @media(max-width: 991px){
                font-size: 12px;
            }
            @media(max-width: 767px){
                font-size: 18px;
            }
            a {
                color: $quarternarycolor;
            }
        }
    }

}

#footer {
    background-color: $quarternarycolor;
    color: $primarycolor;
    padding: 25px 0;
    @media(max-width: 991px){
        padding: 15px 0;
    }
    @media(max-width: 767px){
        padding: 22px 0;
    }
    .container {
        @media(min-width: 992px){
            max-width: 1316px;
        }
        .footer_top {
            display: flex;
            justify-content: left;
            max-width: 1316px;
            column-gap: 50px;
            position: relative;
            padding-bottom: 90px;
            @media(max-width: 991px){
                column-gap: 20px;
            }
            @media(max-width: 767px){
                flex-wrap: wrap;
                row-gap: 30px;
                padding-bottom: 0;
            }
            .footer_column {
                font-size: 18px;
                @media(max-width: 991px){
                    font-size: 10px;
                }
                @media(max-width: 767px){
                    font-size: 18px;
                }
                &.logo {
                    width: 150px;
                    padding-right: 20px;
                    @media(max-width: 991px){
                        width: 90px;
                        padding-right: 10px;
                    }
                    @media(max-width: 767px){
                        width: 100%;
                    }
                    img {
                        width: 100%;
                        @media(max-width: 767px){
                            width: 150px;
                        }
                    }
                }
                &.address {
                    width: 290px;
                    @media(max-width: 991px){
                        width: 170px;
                    }
                    @media(max-width: 767px){
                        width: 100%;
                    }
                }
                &.pages {
                    width: 100px;
                    @media(max-width: 991px){
                        width: 120px;
                    }
                    @media(max-width: 767px){
                        width: calc(50% - 10px);
                    }
                }
                &.cqc {
                    margin-left: auto;
                    width: 300px;
                    @media(max-width: 991px){
                        width: 170px;
                    }
                    @media(max-width: 767px){
                        width: 100%;
                    }
                }
                h4 {
                    font-weight: bold;
                    font-size: 18px;
                    margin-bottom: 30px;
                    @media(max-width: 767px){
                        margin-bottom: 20px;
                    }
                }
                a {
                    color: $primarycolor;
                }
                ul {
                    margin: 0;
                    padding: 0;
                    li {
                        padding: 0;
                        list-style: none;
                        margin-bottom: 20px;
                        @media(max-width: 991px){
                            margin-bottom: 10px;
                        }
                        @media(max-width: 767px){
                            margin-bottom: 20px;
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        .footer_logos {
            display: flex;
            justify-content: left;
            row-gap: 15px;
            column-gap: 10px;
            padding: 20px 0;
            flex-wrap: wrap;
            width: 450px;
            position:absolute;
            bottom: 0;
            left: 0;
            @media(max-width: 991px){
                width: auto;
                column-gap: 5px;
                row-gap: 5px;
            }
            @media(max-width: 767px){
                position: static;
            }
            .mid_logo {
                width: 140px;
                text-align: center;
                @media(max-width: 991px){
                    width: 100px;
                }
                img {
                    height: 47px;
                    @media(max-width: 991px){
                        height: 32px;
                    }
                }
            }
            .bot_logo {
                img {
                    height: 50px;
                    @media(max-width: 991px){
                        height: 25px;
                        @media(max-width: 767px){
                            height: 36px;
                        }
                    }
                }
            }
        }

        .footer_bottom {
            border-top: 2px solid $primarycolor;
            display: flex;
            justify-content: space-between;
            padding: 25px 0;
            font-size: 18px;
            @include koara;
            @media(max-width: 991px){
                padding: 12px 0;
                font-size: 12px;
            }
            @media(max-width: 767px){
                flex-direction: column;
                padding: 40px 0;
                row-gap: 30px;
                font-size: 18px;
            }
            a {
                color: $primarycolor;
            }
        }
    }
}

#open_nav {
    position: fixed;
    bottom: 24px;
    right: 24px;
    z-index: 500;
    a {
        display: block;
        width: 82px;
        height: 82px;
        border-radius: 50%;
        background: $primarycolor;
        background: linear-gradient(160deg, rgba(142,98,137,1) 0%, rgba(81,27,74,1) 100%);
        color: #fff;
        font-size: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none !important;
    }
}

#banner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 400px;
    background-repeat: no-repeat;
    background-position: 50% top;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    @media(max-width: 767px){
        height: 275px;
    }
    .overlay {
        @include overlay;
        background-color: rgba(0,0,0,0.4);
    }
    .banner_contents {
        position: relative;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        max-width: 800px;
        z-index: 99;
        padding-bottom: 30px;
        h1 {
            color: $quarternarycolor;
            font-size: 48px;
            line-height: 1em;
            margin-bottom: 10px;
            @media(max-width: 991px){
                font-size: 38px;
            }
            @media(max-width: 767px){
                font-size: 36px;
            }
        }
        h2 {
            color: $quarternarycolor;
            font-size: 42px;
            @include koara;
            margin: 0;
            @media(max-width: 991px){
                font-size: 32px;
            }
            @media(max-width: 767px){
                font-size: 28px;
            }
        }
    }
    &.home_banner {
        height: 880px;
        align-items: center;
        .overlay {
            background-color: rgba(81,27,74,0.59);
        }
        @media(max-width: 991px){
            height: 746px;
        }
        @media(max-width: 767px){
            height: 616px;
        }
        .banner_contents {
            align-items: center;
            padding-bottom: 0;
            max-width: 650px;
            @media(max-width: 991px){
                padding-top: 50px;
            }
            @media(max-width: 767px){
                padding-top: 100px;
                max-width: 322px;
            }
            h1 {
                color: $quarternarycolor;
                font-size: 90px;
                margin-bottom: 10px;
                @media(max-width: 991px){
                    font-size: 50px;
                    margin-bottom: 30px;
                    padding: 0 65px;
                }
                @media(max-width: 767px){
                    font-size: 44px;
                    margin-bottom: 12px;
                    padding: 0;
                }
            }
            h2 {
                font-size: 48px;
                margin-bottom: 20px;
                @media(max-width: 991px){
                    font-size: 36px;
                    margin-bottom: 25px;
                }
                @media(max-width: 767px){
                    font-size: 16px;
                    margin-bottom: 15px;
                }
            }
            .buttons {
                display: flex;
                flex-direction: row;
                row-gap: 10px;
                column-gap: 16px;
                flex-wrap: wrap;
                .btn {
                    width: 315px;
                    font-size: 18px;
                    font-weight: bold;
                }
                @media(max-width: 767px){
                    flex-direction: column;
                    align-items: center;
                    .btn {
                        width: 212px;
                        row-gap: 8px;
                    }
                }
            }
        }
    }
    &.search_banner {
        background-color: $secondarycolor;
        height: 670px;
        padding-top: 170px;
        align-items: center;
        @media(max-width: 991px){
            height: 500px;
            padding-top: 140px;
        }
        &.short {
            height: 450px;
        }
        .overlay {
            @include overlay;
            mix-blend-mode: multiply;
            background-image: url('../images/blobs-bg.png');
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 30px;
        }
        .banner_contents {
            width: 100%;
            @media(max-width: 991px){
                padding: 0 30px;
            }
            @media(max-width: 767px){
                padding: 0 24px;
            }
            h1 {
                margin-bottom: 12px;
            }
            .search_form {
                width: 100%;
                .search_field {
                    margin-bottom: 16px;
                    input {
                        border-radius: 9px;
                        border: 0;
                        background-color: #fff;
                        width: 100%;
                        @include brandon-regular;
                        font-size: 20px;
                        padding: 15px 10px;
                        @media(max-width: 991px){
                            padding: 8px 20px;
                        }
                        &::placeholder {
                            color: $primarycolor;
                        }
                        &::-ms-input-placeholder {
                            color: $primarycolor;
                        }
                    }
                }
                .submit {
                    input {
                        font-size: 18px;
                        padding: 15px 32px;
                        @media(max-width: 991px){
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}

#page_body {
    background-image: url('../images/page-bg-left.png'), url('../images/page-bg-right.png');
    background-position: top left, top right;
    background-repeat: repeat-y, repeat-y;
    background-size: 600px auto, 600px auto;
    background-color: #f3e7d9;
    &.padding_bottom {
        padding-bottom: 40px;
    }
    &.purple_banner {
        padding-bottom: 0;
        position: relative;
        .overlay {
            @include overlay;
            background: rgba(81, 26, 74, 0.9);
            mix-blend-mode: multiply;
        }
        .container {
            position: relative;
            z-index: 99;
        }
    }
}

/**** reusable bits ****/

.block_section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 16px;
    flex-wrap: wrap;
    padding-bottom: 30px;
    @media(max-width: 991px){
        margin-bottom: 20px;
    }
    @media(max-width: 767px){
        flex-direction: column;
        row-gap: 20px;
    }
}

.block {
    padding: 30px;
    background: $primarycolor;
    color: $quarternarycolor;
    border-radius: 30px;
    aspect-ratio: 1; // make it same width as height (square)
    background-size: 100% auto;
    position: relative;
    &.secondary_block {
        background-color: $secondarycolor;
    }
    &.tertiary_block {
        background-color: $tertiarycolor;
        .block_overlay {
            background: linear-gradient(0deg, rgba(218,146,101,1) 0%, rgba(218,146,101,1) 20%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%);
        }
    }
    &.highlight_block {
        background-color: $highlightcolor;
        color: $primarycolor;
        .block_title {
            color: $primarycolor;
        }
    }
    &.transparent_block {
        background: transparent;
        color: $quarternarycolor;
    }
    @media(max-width: 991px){
        padding: 20px 16px;
    }
    @media(max-width: 767px){
        width: 100%;
        padding: 20px 15px;
    }
    .block_overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(0deg, rgba(81,27,74,1) 0%, rgba(81,27,74,1) 20%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%);
        border-radius: 30px;
    }
    .block_title {
        @include koara-bold;
        font-size: 48px;
        margin-bottom: 20px;
        color: $quarternarycolor;
        @media(max-width: 1399px){
            font-size: 40px;
        }
        @media(max-width: 1199px){
            font-size: 32px;
        }
        @media(max-width: 991px){
            font-size: 22px;
            margin-bottom: 12px;
        }
        @media(max-width: 767px){
            font-size: 20px;
            margin-bottom: 10px;
        }
    }
    .block_subtitle {
        font-size: 28px;
        margin-bottom: 20px;
        min-height: 110px;
        @media(max-width: 1399px){
            font-size: 24px;
        }
        @media(max-width: 1199px){
            font-size: 20px;
            min-height: auto;
        }
        @media(max-width: 991px){
            font-size: 16px;
            line-height: 17px;
            margin-bottom: 12px;
        }
        @media(max-width: 767px){
            margin-bottom: 10px;
        }
    }
    .block_link {
        line-height: 1em;;
        .btn {
            font-size: 24px;
            font-weight: bold;
            padding: 11px 16px;
            line-height: 1em;
            @media(max-width: 1199px){
                font-size: 18px;
                padding: 10px 16px;
            }
            @media(max-width: 991px){
                font-size: 14px;
                padding: 8px 20px;
            }
            @media(max-width: 767px){
                padding: 8px 28px;
            }
        }
    }
    .block_contents {
        color: $quarternarycolor;
        height: auto;
        flex-grow: 0;
        position: relative;
        z-index: 99;
    }
}

.image_block {
    background-color: $secondarycolor;
    background-repeat: no-repeat;
    background-size: 100% auto;
    border-radius: 30px;
    aspect-ratio: 1;
    &::after {
        padding-top: 100%;
    }
}

/************ homepage *********/
#strapline {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    padding: 50px;
    color: $primarycolor;
    &.left_strapline {
        justify-content: left;
        text-align: left;
        padding: 50px 0;
    }
    &.less_padding {
        padding-bottom: 30px;
    }
    h2 {
        font-size: 24px;
    }
    p {
        margin: 0;
        font-size: 20px;
        @media(max-width: 767px){
            font-size: 18px;
        }
    }
    &.home_strapline {
        p {
            font-size: 28px;
            @media(max-width: 1199px){
                font-size: 24px;
            }
        }
    }
    @media(max-width: 1199px){
        padding: 40px 0;
    }
    @media(max-width: 991px){
        padding: 30px 0;
    }
}

#related_pages {
    padding-bottom: 10px;
    .section_heading {
        text-align: center;
        h2 {
            font-size: 48px;
            margin-bottom: 30px;
            @media(max-width: 991px){
                font-size: 28px;
                margin-bottom: 20px;
            }
        }
    }
    .block {
        width: calc(50% - 8px);
        display: flex;
        align-items: flex-end;
        @media(max-width: 767px){
            width: 100%;
            height: auto;
        }
    }
}

#home_impact {
    border-radius: 30px;
    background-color: $secondarycolor;
    padding: 30px;
    color: $quarternarycolor;
    margin-bottom: 30px;
    position: relative;
    .overlay {
        @include overlay;
        mix-blend-mode: multiply;
        background-image: url('../images/blobs-bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 30px;
        @media(max-width: 767px){
            background-image: url('../images/blobs-bg-mob.png');
        }
    }
    .impact_contents {
        position: relative;
        z-index: 99;
        .impact_title {
            text-align: center;
            font-size: 48px;
            color: $quarternarycolor;
            @include koara-bold;
            @media(max-width: 1199px){
                font-size: 38px;
            }
            @media(max-width: 991px){
                font-size: 28px;
            }
            @media(max-width: 767px){
                font-size: 20px;
                text-align: left;
            }
        }
        .impact_subtitle {
            text-align: center;
            font-size: 28px;
            margin-bottom: 24px;
            @media(max-width: 1199px){
                font-size: 24px;
            }
            @media(max-width: 991px){
                margin-bottom: 20px;
                font-size: 20px;
            }
            @media(max-width: 767px){
                font-size: 16px;
                text-align: left;
            }
        }
        .impact_items {
            display: flex;
            column-gap: 16px;
            flex-direction: row;
            @media(max-width: 767px){
                flex-direction: column;
                row-gap: 16px;
            }
            .impact_item {
                width: calc(33.33% - 11px);
                @media(max-width: 767px){
                    width: 100%;
                }
                .impact_item_icon {
                    font-size: 90px;
                    line-height: 1em;
                    margin-bottom: 20px;
                    @media(max-width: 1199px){
                        font-size: 64px;
                    }
                    @media(max-width: 991px){
                        font-size: 38px;
                    }
                    @media(max-width: 767px){
                        font-size: 56px;
                        width: auto;
                    }
                }
                .impact_item_title {
                    font-size: 28px;
                    font-weight: bold;
                    margin-bottom: 4px;
                    @media(max-width: 1199px){
                        font-size: 26px;
                    }
                    @media(max-width: 991px){
                        font-size: 23px;
                    }
                }
                .impact_item_subtitle {
                    font-size: 23px;
                    @media(max-width: 1199px){
                        font-size: 20px;
                    }
                    @media(max-width: 991px){
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

#home_story, #home_whats_on {
    @media(max-width: 767px){
        flex-direction: column-reverse;
        row-gap: 0;
    }
    .block {
        width: calc(50% - 8px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
        @media(max-width: 767px){
            width: 100%;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            aspect-ratio: 1.69;
        }
        .whats_on_event {
            font-size: 28px;
            strong {
                color: $quarternarycolor;
            }
            @media(max-width: 991px){
                font-size: 16px;
            }
        }
    }
    .image_block {
        width: calc(50% - 8px);
        @media(max-width: 767px){
            width: 100%;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            aspect-ratio: 1.69;
            background-position: center;
        }
    }
}

#lottery_block {
    border-radius: 30px;
    background: $tertiarycolor;
    padding: 30px;
    color: $quarternarycolor;
    margin-bottom: 30px;
    text-align: center;
    position: relative;
    @media(max-width: 991px){
        margin-bottom: 20px;
    }
    .overlay {
        @include overlay;
        mix-blend-mode: multiply;
        background-image: url('../images/blobs-bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 30px;
        @media(max-width: 767px){
            background-image: url('../images/blobs-bg-mob.png');
        }
    }
    .lottery_contents {
        position: relative;
        z-index: 99;
        .lottery_title {
            font-size: 48px;
            margin-bottom: 24px;
            @include koara-bold;
            color: $quarternarycolor;
            @media(max-width: 991px){
                font-size: 28px;
                margin-bottom: 7px;
            }
            @media(max-width: 767px){
                font-size: 20px;
                margin-bottom: 16px;
            }
            .amount {
                @media(max-width: 767px){
                    font-size: 36px;
                }
            }
        }
        .lottery_subtitle {
            font-size: 28px;
            color: $quarternarycolor;
            @media(max-width: 991px){
                font-size: 16px;
                margin-bottom: 7px;
            }
            @media(max-width: 767px){
                font-size: 18px;
            }
        }
        .lottery_next_draw {
            font-size: 36px;
            margin-bottom: 24px;
            @media(max-width: 991px){
                font-size: 22px;
                margin-bottom: 9px;
            }
            @media(max-width: 767px){
                font-size: 14px;
            }
            strong{
                color: $quarternarycolor;
            }
            .time {
                @include koara;
                @media(max-width: 767px){
                    display: block;
                    font-size: 36px;
                    margin-top: 8px;
                }
            }
        }
        .block_link {
            .btn {
                font-size: 24px;
                font-weight: bold;
                padding: 11px 16px;
                line-height: 1em;
                @media(max-width: 991px){
                    font-size: 14px;
                    padding: 8px 20px;
                }
            }
        }
    }
}

#home_help_us {
    display: flex;
    justify-content: center;
    text-align: center;
    color: $quarternarycolor;
    flex-direction: column;
    border-radius: 30px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    aspect-ratio: 2.02;
    @media(max-width: 767px){
        aspect-ratio: 1;
    }
    .overlay {
        @include overlay;
        background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(81,27,74,1) 100%);
        border-radius: 30px;
    }
    .help_us_contents {
        position: relative;
        z-index: 99;
        .help_us_title {
            font-size: 90px;
            @include koara-bold;
            margin-bottom: 25px;
            color: $quarternarycolor;
            @media(max-width: 991px){
                font-size: 28px;
                margin-bottom: 11px;
            }
        }
        .help_us_subtitle {
            font-size: 28px;
            margin-bottom: 50px;
            @media(max-width: 991px){
                font-size: 16px;
                margin-bottom: 17px;
            }
            @media(max-width: 767px){
                font-size: 14px;
                margin-bottom: 20px;
            }

        }
        .help_us_link {
            .btn {
                font-size: 24px;
                font-weight: bold;
                padding: 11px 16px;
                line-height: 1em;
                @media(max-width: 991px){
                    font-size: 14px;
                    padding: 8px 20px;
                }
            }
        }
    }
}

.listing_blocks {
    padding-bottom: 40px;

    h2 {
        color: $primarycolor;
        margin-bottom: 20px;
        font-size: 28px;
    }

    .blocks {
        display: flex;
        justify-content: center;
        column-gap: 20px;
        row-gap: 20px;
        flex-wrap: wrap;
        @media(max-width: 991px){
            column-gap: 16px;
            row-gap: 16px;
        }
        @media(max-width: 767px){
            row-gap: 20px;
        }
        .listing_block {
            width: calc(33.33% - 13.33px);
            background-color: $primarycolor;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            color: $quarternarycolor;
            border-radius: 15px;
            position: relative;
            aspect-ratio: 1;
            @media(max-width: 1199px){
                width: calc(50% - 10px);
            }
            @media(max-width: 991px){
                width: calc(50% - 8px);
            }
            @media(max-width: 767px){
                width: 100%;
            }

            &.secondary {
                background-color: $secondarycolor;
            }
            &.tertiary {
                background-color: $tertiarycolor;

            }
            &.white {
                background-color: #fff;
            }

            .gradient_overlay {
                @include overlay;
                border-radius: 15px;
                background: linear-gradient(0deg, rgba(81,27,74,1) 0%, rgba(142, 98, 137, .62) 100%);
                &.secondary {
                    background: linear-gradient(0deg, rgba(160, 78, 94, 1) 0%, rgba(160, 78, 94, .04) 100%);
                }
                &.tertiary {
                    background: linear-gradient(0deg, rgba(218, 146, 101, 1) 0%, rgba(142, 98, 137, 0.62) 100%);
                }
            }

            h3, h2 {
                margin-bottom: 16px;
                font-size: 28px;
                @media(max-width: 991px){
                    font-size: 22px;
                    margin-bottom: 10px;
                }
                a {
                    color: $quarternarycolor;
                }
            }
            p {
                font-size: 19px;
                margin-bottom: 20px;
                @media(max-width: 991px){
                    font-size: 14px;
                }
                strong {
                    color: $quarternarycolor;
                }
            }

            .button {
                .btn {
                    font-size: 24px;
                    font-weight: bold;
                    @media(max-width: 1199px){
                        font-size: 14px;
                    }
                }
            }

            .partner_details {
                padding: 30px 16px;
                position: relative;
                z-index: 99;
            }

            .image {
                img {
                    border-top-left-radius: 15px;
                    border-top-right-radius: 15px;
                    width: 100%;
                }
            }
            .full_title {
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                aspect-ratio: 2.96;
                h2 {
                    font-size: 38px;
                    margin: 0;
                    line-height: 1em;
                    @media(max-width: 1399px){
                        font-size: 32px;
                    }
                    @media(max-width: 1199px){
                        font-size: 28px;
                    }
                }
            }
            .content {
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;
                padding: 30px 15px;
                .link {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    a {
                        padding: 11px 16px;
                        font-size: 24px;
                        line-height: 1em;
                    }
                    .date {
                        font-size: 20px;
                    }
                }
            }

            .product_detail {
                text-align: center;
                padding: 30px;
                h2 {
                    font-size: 28px;
                    margin-bottom: 20px;
                }
                .product_price {
                    font-size: 19px;
                    margin-bottom: 20px;
                }
                .product_stock {
                    font-size: 19px;
                    margin-bottom: 20px;
                }
                .button {
                    .btn {
                        font-size: 24px;
                        padding: 10px 11px;
                    }
                }
            }
        }
    }
    .pagination {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        .prev, .next {
            width: 100px;
            &.next {
                text-align: right;
            }
            .btn {
                font-size: 18px;
                padding: 11px 16px;
                font-weight: bold;
                height: auto;
                @media(max-width: 991px){
                    font-size: 14px;
                    padding: 8px 16px;
                }
            }
        }
        .pages {
            text-align: center;
            font-size: 20px;
            @media(max-width: 991px){
                font-size: 14px;
            }
            .page {
                color: $primarycolor;
                @include koara-bold;
                &.all {
                    color: $secondarycolor;
                    a {
                        color: $secondarycolor;
                    }
                }
                a {
                    text-decoration: underline;
                }
            }
        }
    }
}

#partner_listing, #supporter_listing {
    .blocks {
        .listing_block {
            background-size: 80%;
        }
    }
}

#team_filters {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    .field_wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
}

.team_listing_block {
    width: calc(33.33% - 13.33px);
    position: relative;
    padding-top: 55px;
    @media(max-width: 1199px){
        width: calc(50% - 10px);
    }
    @media(max-width: 991px){
        width: calc(50% - 8px);
    }
    @media(max-width: 767px){
        width: 100%;
    }
    .image {
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -103px;
        border-radius: 50%;
        @media(max-width: 767px){
            margin-left: -70px;
        }
        img {
            border: 2px solid #fff;
            width: 206px;
            border-radius: 50%;
            @media(max-width: 767px){
                width: 140px;
            }
        }
    }
    .details {
        padding: 170px 20px 40px;
        background-color: $primarycolor;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        color: $quarternarycolor;
        border-radius: 15px;
        aspect-ratio: 1;
        @media(max-width: 767px){
            aspect-ratio: auto;
            padding-top: 96px;
        }
        h3 {
            color: $quarternarycolor;
            font-size: 24px;
            margin-bottom: 16px;
            line-height: 1.5em;
            @media(max-width: 767px){
                font-size: 20px;
            }
        }
        p {
            font-size: 19px;
            line-height: 1.6em;
            @media(max-width: 767px){
                font-size: 15px;
            }
        }
        .more_info {
            a {
                color: $quarternarycolor;
                font-weight: bold;
            }
        }
    }
}

#memberModal {
    .modal-dialog {
        max-width: 590px;
    }
}

.team_member_detail {
    padding-top: 75px;
    position: relative;
    pointer-events: auto;
    position: relative;
    width: 100%;
    .image {
        position: absolute;
        z-index: 20;
        top: 0;
        left: 50%;
        margin-left: -137px;
        border-radius: 50%;
        @media(max-width: 767px){
            margin-left: -70px;
        }
        img {
            border: 2px solid #fff;
            width: 274px;
            border-radius: 50%;
            @media(max-width: 767px){
                width: 140px;
            }
        }
    }
    .details {
        padding: 230px 20px 50px;
        background: linear-gradient(0deg, rgba(81,27,74,1) 0%, rgba(116, 72, 110) 100%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        color: $quarternarycolor;
        border-radius: 25px;
        position: relative;
        z-index: 10;
        @media(max-width: 767px){
            padding-top: 90px;
        }
        .close {
            position: absolute;
            top: 15px;
            right: 20px;
            z-index: 30;
            font-size: 50px;
            line-height: 1em;
            color: $quarternarycolor;
            text-decoration: none !important;
        }
        h3 {
            color: $quarternarycolor;
            font-size: 24px;
            margin-bottom: 30px;
            line-height: 1.5em;
            @media(max-width: 767px){
                font-size: 20px;
            }
        }
        p {
            font-size: 19px;
            line-height: 1.6em;
            @media(max-width: 767px){
                font-size: 15px;
            }
        }
    }
}

#donate {
    @media(max-width: 767px){
        flex-direction: column;
        row-gap: 0;
    }
    &.text_only {
        display: flex;
        justify-content: center;
    }
    .block {
        width: calc(50% - 8px);
        @media(max-width: 767px){
            width: 100%;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            aspect-ratio: unset;
        }
        .block_title {
            @media(max-width: 1399px){
                margin-bottom: 10px;
            }
        }
        .block_subtitle {
            min-height: auto;
            @media(max-width: 1399px){
                font-size: 20px;
                margin-bottom: 12px;
            }
            @media(max-width: 1199px){
                font-size: 18px;
            }
            @media(max-width: 991px){
            }
        }
        .donate_types {
            display: flex;
            flex-direction: row;
            justify-content: left;
            column-gap: 20px;
            font-size: 22px;
            margin-bottom: 20px;
            @media(max-width: 1399px){
                font-size: 18px;
                column-gap: 9px;
            }
            @media(max-width: 1199px){
                font-size: 14px;
                margin-bottom: 14px;
                column-gap: 6px;
            }
            @media(max-width: 991px){
                column-gap: 6px;
                font-size: 11px;
                margin-bottom: 10px;
            }
            @media(max-width: 767px){
                font-size: 14px;
                column-gap: 10px;
                flex-wrap: wrap;
            }
        }
        h4 {
            font-size: 26px;
            @include koara;
            line-height: 1em;
            margin-bottom: 15px;
            @media(max-width: 1199px){
                font-size: 24px;
                margin-bottom: 12px;
            }
            @media(max-width: 1399px){
                font-size: 22px;
            }
            @media(max-width: 991px){
                font-size: 13px;
                margin-bottom: 10px;
            }
        }
        .donate_amounts {
            margin-bottom: 20px;
            display: flex;
            flex-direction: row;
            justify-content: left;
            column-gap: 20px;
            @media(max-width: 1399px){
                column-gap: 12px;
            }
            @media(max-width: 1199px){
                margin-bottom: 12px;
            }
            @media(max-width: 991px){
                column-gap: 5px;
                margin-bottom: 8px;
            }
            @media(max-width: 767px){
                column-gap: 12px;
                margin-bottom: 12px;
            }
            .donate_amount {
                font-size: 24px;
                padding: 11px 16px;
                @media(max-width: 1399px){
                    padding: 8px 12px;
                    font-size: 16px;
                }
                @media(max-width: 1199px){
                    padding: 5px 10px;
                    font-size: 13px;
                }
                @media(max-width: 991px){
                    font-size: 10px;
                }
                @media(max-width: 767px){
                    padding: 8px 12px;
                    font-size: 14px;
                }
            }
        }
        .donate_amount_other {
            margin-bottom: 30px;
            display: none;
            @media(max-width: 1399px){
                margin-bottom: 20px;
            }
            @media(max-width: 1199px){
                margin-bottom: 14px;
            }
            @media(max-width: 991px){
                margin-bottom: 8px;
            }
            .form-control {
                font-size: 24px;
                @media(max-width: 1399px){
                    font-size: 18px;
                }
                @media(max-width: 1199px){
                    font-size: 15px;
                }
                @media(max-width: 991px){
                    font-size: 12px;
                }
            }
        }
        .donate_gift_aid {
            margin-bottom: 20px;
            font-size: 22px;
            @media(max-width: 1399px){
                font-size: 18px;
                margin-bottom: 12px;
            }
            @media(max-width: 1199px){
                font-size: 15px;
                margin-bottom: 9px;
            }
            @media(max-width: 991px){
                font-size: 12px;
                margin-bottom: 5px;
            }
            @media(max-width: 767px){
                font-size: 14px;
            }
        }
        .block_link {
            .btn {
                @media(max-width: 1399px){
                    font-size: 20px;
                    @media(max-width: 1199px){
                        padding: 5px 8px;
                        font-size: 16px;
                    }
                }
            }
        }
    }
    .image_block {
        width: calc(50% - 8px);
        @media(max-width: 767px){
            width: 100%;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            aspect-ratio: 1.05;
            background-position: center;
        }
    }
}

// CONTENT PAGES
#page_content {
    padding: 30px 0;
}

/** our work specifics */
#what_to_expect {
    padding-bottom: 40px;
}

#our_work_item_content {
    padding-top: 50px;
    .intro {
        text-align: center;
        font-size: 26px;
        margin-bottom: 30px;
        @media(max-width: 991px){
            font-size: 20px;
            margin-bottom: 20px;
        }
    }
    .content_top {
        margin-bottom: 15px;
    }
    .content_bottom {
        margin-bottom: 15px;
    }
}

#useful_links {
    border-radius: 30px;
    background: $tertiarycolor;
    padding: 30px;
    color: $quarternarycolor;
    margin-bottom: 30px;
    text-align: center;
    position: relative;
    @media(max-width: 991px){
        margin-bottom: 20px;
    }
    .overlay {
        @include overlay;
        mix-blend-mode: multiply;
        background-image: url('../images/blobs-bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 30px;
        @media(max-width: 767px){
            background-image: url('../images/blobs-bg-mob.png');
        }
    }
    .link_contents {
        position: relative;
        z-index: 99;
        h2 {
            color: $quarternarycolor;
            font-size: 48px;
            margin: 0;
        }
        .subtitle {
            margin-bottom: 24px;
        }
        .links {
            padding: 0;
            margin: 0;
            li {
                list-style: none;
                padding: 0;
                a {
                    text-decoration: underline;
                    color: $quarternarycolor;
                }
            }
        }
    }
}

.partner_logo {
    padding: 16px;
    width: 116px;
    height: 116px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    @media(max-width: 991px){
        width: 80px;
        height: 80px;
    }
    img {
        width: 100%;
        height: auto;
    }
}

#partner_detail {
    .detail {
        .sidebar {
            background-size: 80%;
        }
    }
}

// currently partners and vacancies and events
.two_col_section {
    padding: 40px 0;
    .detail {
        display: flex;
        justify-content: space-between;
        align-items: top;
        @media(max-width: 767px){
            flex-direction: column-reverse;
            row-gap: 30px;
        }
        .description {
            width: calc(100% - 470px);
            font-size: 20px;
            line-height: 29px;
            @media(max-width: 991px){
                width: calc(100% - 370px);
            }
            @media(max-width: 767px){
                width: 100%;
            }
        }
        .sidebar {
            width: 440px;
            border-radius: 15px;
            padding: 30px;
            align-self: flex-start;
            background-repeat: no-repeat;
            background-size: cover;
            background-color: #fff;
            background-position: center;
            color: $quarternarycolor;
            position: sticky;
            top: 123px;
            @media(max-width: 991px){
                width: 340px;
                top: 0;
                padding: 30px 24px;
            }
            @media(max-width: 767px){
                width: 100%;
                padding: 20px;
                position: relative;
            }
            .sidebar_overlay {
                @include overlay;
                background: linear-gradient(0deg, rgba(81,27,74,1) 0%, rgba(142, 98, 137, .62) 100%);
                border-radius: 15px;
            }
            .contents {
                position: relative;
                z-index: 99;
            }
            h3 {
                font-size: 28px;
                margin-bottom: 20px;
                color: #fff;
                line-height: 1em;
                @media(max-width: 991px){
                    font-size: 22px;
                    margin-bottom: 10px;
                }
                @media(max-width: 767px){
                    font-size: 20px;
                    margin-bottom: 6px;
                }
            }
            .sidebar_item {
                display: flex;
                align-items: left;
                margin-bottom: 10px;
                font-size: 20px;
                &.full_width {
                    display: block;
                    .title {
                        margin-bottom: 10px;
                        margin-right: 0;
                    }
                }
                @media(max-width: 1399px){
                    font-size: 18px;
                    margin-bottom: 8px;
                }
                @media(max-width: 1199px){
                    font-size: 15px;
                    margin-bottom: 6px;
                }
                .title {
                    color: #fff;
                    margin-right: 20px;
                    font-size: 22px;
                    @include koara-bold;
                    @media(max-width: 1399px){
                        width: 135px;
                    }
                    @media(max-width: 1199px){
                        width: 115px;
                        font-size: 16px;
                    }
                }
            }
            p {
                font-size: 19px;
                line-height: 1.75em;
                @media(max-width: 991px){
                    font-size: 17px;
                    line-height: 1.25em;
                }
                @media(max-width: 767px){
                    font-size: 15px;
                }
            }
            .link {
                a {
                    font-size: 24px;
                    padding: 11px 16px;
                    font-weight: bold;
                    @media(max-width: 991px){
                        font-size: 16px;
                        padding: 8px 16px;
                    }
                }
            }
            .ticket_types {
                .ticket_type {
                    h3 {
                        font-size: 24px;
                        margin-bottom: 10px;
                    }
                }
            }
            .product_quantity {
                font-size: 20px;
                label {
                    color: #fff;
                }
                .quantity_changer {
                    a {
                        color: $quarternarycolor;
                    }
                }
                .quantity_value {
                    font-size: 20px;
                    color: #fff;
                }
            }
            .buttons {
                margin-top: 30px;
                display: flex;
                column-gap: 10px;
                justify-content: right;
                &.sign_up {
                    justify-content: left;
                    .btn {
                        padding: 9px 30px;
                    }
                }
                .btn {
                    font-size: 18px;
                    padding: 15px;
                    @media(max-width: 991px){
                        font-size: 14px;
                        padding: 8px 20px;
                    }
                }
            }
        }
    }
}

.vacancy_detail_item {
    display: flex;
    align-items: left;
    margin-bottom: 10px;
    font-size: 22px;
    @media(max-width: 1399px){
        font-size: 18px;
        margin-bottom: 8px;
    }
    @media(max-width: 1199px){
        font-size: 16px;
        margin-bottom: 6px;
    }
    .title {
        width: 160px;
        color: #fff;
        @include koara-bold;
        @media(max-width: 1399px){
            width: 135px;
        }
        @media(max-width: 1199px){
            width: 115px;
        }
    }
}

#search_results {
    padding: 40px 0 40px;
    h2 {
        margin-bottom: 16px;
    }
    .search_result {
        margin-bottom: 30px;
        p {
            margin: 0;
            font-size: 16px;
            a {
                text-decoration: underline;
            }
        }
    }
}

/** FAQS (core?) **/

#faqs_content {
    padding-bottom: 40px;
    h2 {
        text-align: center;
    }
}

.faq_accordion {
    background: none !important;
    border: 0;
    .accordion-item {
        background: transparent !important;
        border-bottom: 1px solid $primarycolor;
        &:last-child {
            border-bottom: 1px solid $primarycolor;
        }
        .accordion_header {
            .accordion_head {
                color: $primarycolor;
                padding: 10px 0;
                font-size: 26px;
                border: none;
                display: flex;
                justify-content: space-between;
                &::after {
                    font-family: "Font Awesome 6 Pro";
                    content: "\f0d8";
                    font-weight: 900;
                    font-size: 30px;

                }
                &.collapsed::after {
                    content: "\f0d7";
                }
            }
        }
        .accordion_body {
            padding:  0 0 20px 0;
            font-size: 18px;
            background: none !important;
            color: $primarycolor;
        }
    }
}

/** shop **/
#external_shops {
    padding-bottom: 40px;
    .listing_block {
        height: 445px;
        background-size: cover;
        @media(max-width: 1399px){
            height: 360px;
        }
        @media(max-width: 1199px){
            height: 300px;
        }
        @media(max-width: 991px){
            height: 344px;  
        }
        @media(max-width: 767px){
            height: auto;
            width: 100%;
            aspect-ratio: 1;
        }

        .shop_details {
            padding: 20px;
            position: relative;
            z-index: 99;
            display: flex;
            height: 100%;
            justify-content: flex-end;
            align-items: center;
            flex-direction: column;
            .logo {
                width: 179px;
                margin-bottom: 10px;
                img {
                    width: 100%;
                }
            }
        }
    }
}

#shop_locations {
    .shop_search {
        width: 370px;
        margin: 0 auto 30px;
        .search_form {
            display: flex;
            align-items: left;
            flex-grow: 1;
            .search_field {
                border: 0;
                padding: 0;
                background: transparent;
                border: 1px solid $primarycolor;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                color: $primarycolor;
                padding: 10px;
                width: calc(100% - 88px);
                font-size: 18px;
            }
            .btn {
                border-radius: 0;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                padding: 10px 16px;
                width: 88px;
                font-size: 18px;
            }
        }
        .reset {
            margin-top: 10px;
            font-size: 16px;
            text-align: center;
            a {
                text-decoration: underline;
            }
        }
    }

    .listing_block {
        .location_details {
            padding: 20px;
            position: relative;
            z-index: 99;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: left;
            flex-direction: column;
            h2 {
                font-size: 28px;
                margin-bottom: 20px;
                color: $quarternarycolor;
                text-align: left;
                @media(max-width: 1399px){
                    font-size: 26px;
                }
                @media(max-width: 1199px){
                    font-size: 24px;
                }
            }
            p {
                font-size: 20px;
                line-height: 1.5em;
                @media(max-width: 1399px){
                    font-size: 18px;
                    line-height: 1.25em;
                }
                @media(max-width: 1199px){
                    font-size: 16px;
                    line-height: 1.25em;
                }
            }
            .logo {
                width: 179px;
                margin-bottom: 10px;
                img {
                    width: 100%;
                }
            }
        }
    }
}

#shop_location {
    padding: 50px 0 0 0;
    line-height: 30px;
    font-size: 20px;
    @media(max-width: 767px){
        font-size: 18px;
    }
    .back_link {
        @include koara;
        font-size: 16px;
        margin-bottom: 20px;
        line-height: 1em;
    }
    .details {
        margin : 0 0 50px;
    }
    p {
        line-height: 1.5em;
    }
}

#events_listing {
    &::before {
        content: "";
        display: block;
        height: 103px;
        margin: -103px 0 0;
    }
    .listing_block {
        .event_details {
            padding: 20px;
            position: relative;
            z-index: 99;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: left;
            flex-direction: column;
            p {
                font-size: 23px;
            }
        }
    }
    &.related_events_listing {
        .listing_block {
            &:nth-child(3n){
                @media(max-width: 1199px){
                    display: none;
                }
            }
        }
    }
}

.event_summary {
    h4 {
        color: $primarycolor;
        font-size: 22px;
        @include koara-bold;
        margin-bottom: 0;
    }
}

#featured_article {
    padding-top: 30px;
}

#article_listing {
    &::before {
        content: "";
        display: block;
        height: 103px;
        margin: -103px 0 0;
    }
    .listing_block {
        .content {
            h2 {
                text-align: left;
                font-size: 24px;
                margin-bottom: 10px;
            }
        }
    }
}

#article_detail {
    padding: 40px 0;
    .back {
        @include koara-bold;
        margin-bottom: 35px;
        font-size: 16px;
    }
    .description {
        margin-bottom: 40px; 
    }
    .share {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 10px;
        font-size: 28px;
    }
}

/** vacancies **/

.two_col_block {
    @media(max-width: 767px){
        row-gap: 0;
    }
    &.reverse, &#vacancies_block_1, &#shop_block_1, &#shop_block_3 {
        @media(max-width: 767px){
            flex-direction: column-reverse;
        }
    }
    .block {
        width: calc(50% - 8px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
        @media(max-width: 767px){
            width: 100%;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            aspect-ratio: 1.69;
        }
    }
    .image_block {
        width: calc(50% - 8px);
        @media(max-width: 767px){
            width: 100%;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            aspect-ratio: 1.69;
            background-position: center;
        }
    }
}

#vacancy_listing {
    .blocks {
        padding-bottom: 25px;
    }
    .vacancy_listing_block {
        height: 445px;
        background-size: cover;
        @media(max-width: 1399px){
            height: 360px;
        }
        @media(max-width: 1199px){
            height: 300px;
        }
        @media(max-width: 991px){
            height: 344px;  
        }
        @media(max-width: 767px){
            height: auto;
            width: 100%;
            aspect-ratio: 1;
        }
        .vacancy_content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            z-index: 99;
            color: #fff;
            padding: 30px;
            height: 100%;
            h2 {
                text-align: left;
                a {
                    color: #fff;
                }
            }
            .details {
                .type_link {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .btn {
                        padding: 15px;
                        font-weight: bold;
                        font-size: 18px;
                        @media(max-width: 1199px){
                            padding: 8px 20px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    .view_all_button {
        text-align:center;
        .btn {
            padding: 6px 30px;
            font-weight: bold;
            font-size: 16px;
        }
    }
}

.vacancy_type {
    color: $primarycolor;
    padding: 2px 12px 0;
    border-radius: 20px;
    background: $quarternarycolor;
    @include koara-bold;
    font-size: 20px;
    line-height: 1em;
    height: 40px;
    display: flex;
    align-items: center;
    @media(max-width: 1199px){
        font-size: 15px;
        padding: 10px 16px;
    }
}

#vacancies_care {
    padding-bottom: 50px;
    .care_intro {
        text-align: center;
        h2 {
            font-size: 48px;
            margin-bottom: 20px;
            @media(max-width: 991px){
                font-size: 28px;
            }
        }
    }
}

.listing_filters {
    h2 {
        text-align: center;
        font-size: 28px;
        margin-bottom: 30px;
    }
    .filters {
        display: flex;
        flex-wrap: wrap;
        column-gap: 40px;
        row-gap: 20px;
        padding-bottom: 30px;
        justify-content: space-between;
        .filter {
            width: 330px;
            label {
                font-size: 24px;
                margin-bottom: 10px;
                @include koara-bold;
            }
            input, select {
                background-color: transparent;
                border-color: $primarycolor;
                width: 100%;
            }
        }
    }
    .submit {
        display: flex;
        flex-direction: column;
        align-items: center;
        button {
            font-size: 24px;
            padding: 11px 16px;
            margin-bottom: 20px;
            display: inline-block;
        }
        a {
            font-size: 20px;
            text-decoration: underline;
        }
    }
}

#vacancy_filters {
    width: 900px;
    padding: 0 100px 30px;
    @media(max-width: 991px){
        width: auto;
        padding: 0 0 30px;
    }
}

#no_vacancies {
    border-radius: 30px;
    background: $secondarycolor;
    padding: 30px;
    color: $quarternarycolor;
    margin-bottom: 30px;
    text-align: center;
    position: relative;
    @media(max-width: 991px){
        margin-bottom: 20px;
    }
    .overlay {
        @include overlay;
        mix-blend-mode: multiply;
        background-image: url('../images/blobs-bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 30px;
        @media(max-width: 767px){
            background-image: url('../images/blobs-bg-mob.png');
        }
    }
    .content {
        position: relative;
        z-index: 99;
        width: 760px;
        margin: 0 auto;
        h2 {
            color: $quarternarycolor;
            font-size: 48px;
            margin: 0;
        }
        a {
            color: $quarternarycolor;
        }
        .subtitle {
            margin-bottom: 24px;
        }
        .links {
            padding: 0;
            margin: 0;
            li {
                list-style: none;
                padding: 0;
                a {
                    text-decoration: underline;
                    color: $quarternarycolor;
                }
            }
        }
    }
}


#vacancy_detail {
    .benefits_wrapper {
        background: $primarycolor;
        border-radius: 30px;
        color: $quarternarycolor;
        padding: 20px;
        margin-bottom: 30px;
        h3 {
            color: $quarternarycolor;
            font-size: 24px;
            margin-bottom: 10px;
        }
        .benefits {
            display: flex;
            flex-wrap: wrap;
            .benefit {
                width: calc(50% - 1px);
                padding: 20px 20px 20px 0;
                border-top: 1px solid $quarternarycolor;
                &:nth-child(1), &:nth-child(2){
                    border-top: 0;
                }
                &:nth-child(2n){
                    padding: 20px 0 20px 20px;
                    border-left: 1px solid $quarternarycolor;
                }
                @media(max-width: 767px){
                    width: 100%;
                    border-left: 0 !important;
                    padding: 20px 0 !important;
                    font-size: 16px;
                    &:nth-child(2){
                        border-top: 1px solid $quarternarycolor;
                    }
                }
                .icon {
                    margin-bottom: 16px;
                    font-size: 32px;
                }
                h4 {
                    @include koara;
                    font-size: 28px;
                    line-height: 1em;
                    margin-bottom: 16px;
                    @media(max-width: 767px){
                        font-size: 21px;
                        margin-bottom: 10px;
                    }
                }
                p {
                    font-size: 20px;
                    margin-bottom: 0;
                    @media(max-width: 991px){
                        font-size: 18px;
                    }
                }
            }
        }
    }
    .vacancy_testimonial {
        border-radius: 15px;
        background-size: cover;
        position: relative;
        .overlay {
            @include overlay;
            background: linear-gradient(0deg, rgba(160, 78, 94, 1) 0%, rgba(160, 78, 94, 1) 20%, rgba(160, 78, 94, .25) 100%);
            border-radius: 15px;
        }
        .contents {
            position: relative;
            z-index: 99;
            color: #fff;
            padding: 30px 30px 50px;
            .intro {
                @include koara-bold;
                font-size: 24px;
                margin-bottom: 16px;
                color: $quarternarycolor;
            }
            .image {
                width: 66px;
                height: 66px;
                border-radius: 50%;
                margin-bottom: 30px;
                img {
                    border-radius: 50%;
                    width: 100%;
                }
            }
            h3 {
                color: #fff;
                font-size: 28px;
                margin-bottom: 12px;
            }
            .employed {
                @include koara-bold;
                font-size: 22px;
                margin-bottom: 10px;
            }
            .testimonial {
                font-size: 22px;
            }
        }
    }
    .sidebar {
        .contents {
            h3 {
                margin: 0 0 110px;
            }
            .type {
                margin-bottom: 20px;
                .vacancy_type {
                    display: inline-flex;
                }
            }
        }
    }
}

#vacancy_application {
    padding-bottom: 40px;

    .button {
        margin-top: 30px;
        float: right;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 152px;
        height: 56px;
        border: none;
        box-shadow: none;
        border-radius: 10px;
        background-color: #511B4A;
        font-size: 18px;
        color: #F3E7D9;

        &:hover {
            text-decoration: none;
        }

        &--hidden {
            visibility: hidden;
        }

        &--mt-0 {
            margin-top: 0;
        }

    }


}

.buttonset {
    display: flex;
    justify-content: space-between;

    .submit {
        margin-left: auto;
    }

    &--center {
        justify-content: center;
        .button {
            &.proceed {
                border: none;
                box-shadow: none;
                border-radius: 15px;
                background-color: #F6B05B;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 56px;
                width: 110px;
                font-size: 18px;
                line-height: 18px;
                @include brandon-bold;
                color: #511A4A;
            }
        }
    }
}

#purple_section {
    padding: 280px 0 220px;
    h1 {
        color: $quarternarycolor;
        font-size: 48px;
        margin-bottom: 60px;
        @media(max-width: 991px){
            font-size: 43px;
        }
        @media(max-width: 767px){
            font-size: 28px;
        }
    }
    .sign_in_sections, .register_sections {
        color: $quarternarycolor;
        h2 {
            color: $quarternarycolor;
        }
        h3 {
            color: $quarternarycolor;
        }
        p {
            color: $quarternarycolor;
            a {
                color: $quarternarycolor;
            }
        }
        .register_section, .sign_in_section {
            label {
                color: $quarternarycolor;
            }
            input:not([type=checkbox]), select {
                border-bottom: 1px solid $quarternarycolor;
                color: $quarternarycolor;
                &::placeholder {
                    color: #F3E7D9;
                }
            }
        }
        .forgot {
            a {
                color: $tertiarycolor;
            }
        }
    }
}

.sign_in_sections, .register_sections {
    color: $primarycolor;
    h2 {
        font-size: 28px;
        margin-bottom: 30px;
        color: $primarycolor;
        @media(max-width: 991px){
            font-size: 22px;
            margin-bottom: 24px;
        }
        @media(max-width: 767px){
            font-size: 19px;
            margin-bottom: 20px;
        }
    }
    h3 {
        font-size: 24px;
        margin-bottom: 16px;
        color: $primarycolor;
    }
    p {
        color: $primarycolor;
        font-size: 18px;
        margin-bottom: 30px;
        @media(max-width: 991px){
            font-size: 16px;
            margin-bottom: 24px;
        }
        @media(max-width: 767px){
            margin-bottom: 20px;
        }
        a {
            color: $primarycolor;
            text-decoration: underline;
        }
    }

    .register_section, .sign_in_section {
        label {
            color: $primarycolor;
        }
        input:not([type=checkbox]), select {
            background-color: transparent;
            border: none;
            border-radius: 0;
            border-bottom: 1px solid $primarycolor;
            margin-bottom: 30px;
            padding: 10px 0;
            font-size: 24px;
            color: $primarycolor;
            @media(max-width: 991px){
                font-size: 18px;
                margin-bottom: 22px;
            }
            &::placeholder {
                opacity: 0.5;
                color: #9e7c8a;
            }
        }
        select {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23F3E7D9' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
        }
        input::-webkit-calendar-picker-indicator {
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23F3E7D9" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
        }
    }
    .button {
        margin-bottom: 30px;
        .btn {
            font-size: 24px;
            padding: 10px 30px;
            @media(max-width: 991px){
                padding: 11px 22px;
            }
            @media(max-width: 767px){
                padding: 8px 22px;
            }
        }
    }
    .forgot {
        @media(max-width: 767px){
            text-align: center;
        }
        a {
            color: $secondarycolor;
            text-decoration: underline;
        }
    }
}

.sign_in_sections {
    display: flex;
    column-gap: 40px;
    justify-content: space-between;
    align-items: flex-start;
    @media(max-width: 767px){
        flex-direction: column;
    }
    .sign_in_section {
        width: calc(50% - 20px);
        font-size: 18px;
        label {
            display: none;
        }
        @media(max-width: 767px){
            width: 100%;
        }
        .button {
            @media(max-width: 767px){
                text-align: center;
                margin-bottom: 22px;
            }
            .btn {
                @media(max-width: 991px){
                    font-size: 16px;
                    padding: 8px 30px;
                }
                @media(max-width: 767px){
                    font-size: 14px;
                }
            }
        }
    }
}

.register_sections {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    .register_section {
        legend {
            font-size: 18px;
            margin-bottom: 30px;
        }
        .fields {
            align-items: space-between;
            flex-wrap: wrap;
            .field_wrapper {
                width: calc(50% - 38px);
                @media(max-width: 767px){
                    width: 100%;
                }
            }
        }
        label {
            margin-bottom: 20px;
        }
    }
    .register_donation {
        margin-bottom: 50px;
        input {
            background-color: transparent;
            border: 1px solid $quarternarycolor;
            border-radius: 8px;
            width: calc(50% - 38px);
            margin: 0;
            padding: 10px;
            font-size: 18px;
            color: $quarternarycolor;
        }
    }
    .register_keep_in_touch {
        .keep_in_touch {
            .field_wrapper {
                .form-check {
                    label {
                        font-size: 18px;
                    }
                }
            }
        }
    }
    .button {
        text-align: right;
    }
}

#contact_form {
    padding-bottom: 40px;
    .contact_fields {
        border-radius: 15px;
        padding: 30px;
        background: #FBF2E8;
        margin-bottom: 20px;
    }
    h2 {
        font-size: 24px;
    }

    .file_upload {
        margin-bottom: 20px;
        .uploaded_message {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 15px;
        }
    }

    .errorlist {
        list-style: none;
        padding: 10px 0 0;
        color: red;
    }

    .submit {
        text-align: center;
        .btn {
            font-size: 18px;
            padding: 15px 18px;
        }
    }   
}

#contact_addresses {
    padding: 55px 0;
    background: $primarycolor;
    color: $quarternarycolor;
    @media(max-width: 991px){
        padding: 20px 0;
    }
    .container {
        max-width: 1340px;
    }
    .addresses {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 20px;
        .address {
            width: calc(50% - 25px);
            @media(max-width: 767px){
                width: 100%;
            }
            h2 {
                font-size: 25px;
                margin-bottom: 12px;
                display: flex;
                align-items: center;
                color: $quarternarycolor;
                column-gap: 10px;
                @media(max-width: 991px){
                    font-size: 20px;
                    margin-bottom: 5px;
                }
                a {
                    color: $quarternarycolor;
                }
                i {
                    font-size: 24px;
                }
            }
            .address_content {
                font-size: 14px;
                line-height: 30px;
                margin: 0;
            }
        }
    }
}

#contact_success {
    padding: 40px 0;
    font-size: 18px;
    h2 {
        font-size: 24px;
    }
}


#lottery_results {
    padding-bottom: 20px;
    .title {
        text-align: center;
        margin-bottom: 30px;
        @media(max-width: 991px){
            margin-bottom: 20px;
        }
        h2 {
            font-size: 36px;
            margin-bottom: 20px;
            @media(max-width: 991px){
                font-size: 28px;
                margin-bottom: 16px;
            }
            @media(max-width: 767px){
                font-size: 24px;
                margin-bottom: 10px;
            }
        }
        .date {
            font-weight: bold;
            font-size: 28px;
            margin-bottom: 0;
            @media(max-width: 767px){
                font-size: 24px;
            }
        }
    }
    .results_blocks {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        column-gap: 10px;
        flex-wrap: wrap;
        .results_block {
            min-height: 240px;
            padding: 20px;
            border-radius: 30px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            @media(max-width: 991px){
                min-height: 185px;
            }
            h3 {
                margin-bottom: 10px;
                font-size: 28px;
                color: $quarternarycolor;
                @media(max-width: 991px){
                    font-size: 24px;
                }
            }
            .winner {
                font-size: 18px;
                margin: 0;
                color: $quarternarycolor;
            }
            .winners {
                font-size: 18px;
                margin: 0;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                color: $quarternarycolor;
                column-gap: 15px;
                row-gap: 10px;
            }
        }
        .first_prize {
            display: flex;
            justify-content: center;
            .results_block {
                width: 330px;
                background-color: $primarycolor;
                @media(max-width: 991px){
                    width: 270px;
                }
                @media(max-width: 767px){
                    width: 100%;
                }
            }
        }
        .other_prizes {
            display: flex;
            justify-content: center;
            column-gap: 10px;
            @media(max-width: 767px){
                row-gap: 10px;
                flex-wrap: wrap;
            }
            .results_block {
                width: calc(33.333% - 6.5px);
                background-color: $tertiarycolor;
                @media(max-width: 767px){
                    width: 100%;
                    padding: 0 20px;
                    height: 200px;
                }
            }
        }
        .extra_prizes {
            .results_block {
                width: 100%;
                background-color: $secondarycolor;
                padding: 0 200px;
                @media(max-width: 991px){
                    padding: 20px;
                }
            }
        }
    }
    .previous_link {
        padding-top: 20px;
        text-align: center;
    }
}

#lottery_wine_wide {
    padding-bottom: 40px;
    .lwwb_block {
        border-radius: 15px;
        background-color: #511B4A;
        padding: 40px 90px;

        .play-guide {
            margin-bottom: 16px;
            @include koara-bold;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            color: #F3E2D4;
        }

        input[type=checkbox] {
            border-color: #FBF2E8;
        }

        .application_fieldset {
            background-color: #511B4A;
            padding: 0;

            .form-check {
                margin-left: 20px;
                margin-right: 20px;
                text-align: left;
            }

            label {
                color: #F4E3D3;
            }

            #id_member_type {
                display: flex;
                justify-content: center;

                .form-check:first-child{
                    display: none;
                }
            }
            .application_field {
                &.field-member_type {
                    .field_wrapper {
                        text-align: center;
                    }
                }
                &.field-one_off_payment,
                &.field-confirm_direct_debit {
                    display: flex;
                    justify-content: center;
                }

                &.field-member_type,
                &.field-lottery_number,
                &.field-how_many_numbers,
                &.field-how_many_weeks,
                &.field-one_off_payment {
                    width: 100%;
                }
            }
        }
    }
}

#lottery_content {
    border-top: 1px solid $primarycolor;
    padding: 30px 0;
}

#lottery_extras {
    padding-bottom: 40px;
}

#lottery_supporters {
    padding-bottom: 16px;
}

#lottery_supporters_listing {
    .listing_block {
        &:nth-child(3n){
            @media(max-width: 1199px){
                display: none;
            }
        }
    }
}

#supporter {
    padding-top: 103px;
    display: flex;
    justify-content: space-between;
    @media(max-width: 991px){
        padding-top: 138px;
    }
    @media(max-width: 767px){
        padding-top: 108px;
        display: block;
    }
    .dashboard_link_mob {
        padding: 40px 23px 14px;
        background: $primarycolor;
        font-size: 18px;
        font-weight: bold;
        a {
            color: $quarternarycolor;
        }
    }
    .sidebar {
        width: 274px;
        padding-left: 20px;
        background: $primarycolor;
        position: relative;
        padding-bottom: 40px;
        @media(max-width: 991px){
            width: 182px;
        }
        @media(max-width: 767px){
            width: 100%;
            padding: 30px 25px 0;
            background: none;
        }
        .dash_link {
            padding: 60px 8px 40px;
            font-size: 24px;
            font-weight: bold;
            @media(max-width: 991px){
                padding: 55px 8px 40px;
                font-size: 16px;
            }
            a {
                color: $quarternarycolor;
            }
        }
        ul {
            background: #6F3F69;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            padding: 0;
            margin: 0;
            @media(max-width: 767px){
                border-radius: 10px;
            }
            &.sidebar_open {
                li {
                    &.active {
                        border-radius: 0;
                        &:first-child {
                            border-top-left-radius: 10px;
                            border-top-right-radius: 10px;
                        }
                        &:last-child {
                            border-bottom-left-radius: 10px;
                            border-bottom-right-radius: 10px;
                        }
                    }
                    @media(max-width: 767px){
                        display: block;
                    }
                }
            }
            li {
                font-size: 24px;
                margin: 0;
                font-weight: bold;
                position: relative;
                list-style: none;
                @media(max-width: 991px){
                    font-size: 16px;
                }
                @media(max-width: 767px){
                    display: none;
                }
                &:first-child {
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                }
                &:last-child {
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                }
                &.active {
                    background-color: $secondarycolor;
                    @media(max-width: 767px){
                        display: block;
                        border-radius: 10px;
                    }
                    &::after {
                        font-family: "Font Awesome 6 Pro";
                        content: "\f0da";
                        color: $quarternarycolor;
                        position: absolute;
                        right:10px;
                        top: 14px;
                        line-height: 1em;
                        @media(max-width: 991px){
                            top: 10px;
                        }
                        @media(max-width: 767px){
                            display: none;
                        }
                    }
                }
                a {
                    color: $quarternarycolor;
                    padding: 8px;
                    display: block;
                    @media(max-width: 991px){
                        padding: 7px;
                    }
                }
            }
        }
        .open_close {
            position: absolute;
            right: 35px;
            top: 35px;
            color: $quarternarycolor;
            cursor: pointer;
        }
    }
    .main {
        width: calc(100% - 274px);
        padding: 60px 30px;
        @media(max-width: 991px){
            width: calc(100% - 182px);
            padding: 50px 30px;
        }
        @media(max-width: 767px){
            width: 100%;
            padding: 30px 25px;
        }
        h1 {
            font-size: 44px;
            margin-bottom: 20px;
            @media(max-width: 991px){
                font-size: 24px;
            }
        }
        .subtitle {
            font-size: 32px;
            margin-bottom: 24px;
            @media(max-width: 991px){
                font-size: 20px;
                margin-bottom: 20px;
            }
            @media(max-width: 767px){
                font-size: 18px;
            }
        }

    }
    .table {
        @media(max-width: 767px){
            font-size: 14px;
        }
        tr {
            border-bottom: 1px solid $primarycolor;
            td {
                .btn {
                    @media(max-width: 767px){
                        padding: 3px 6px;
                        font-size: 12px;
                    }
                }
            }
        }
    }
    #view_order {
        @media(max-width: 767px){
            font-size: 16px;
        }
        h2 {
            @media(max-width: 767px){
                font-size: 22px;
            }
        }
        h3 {
            @media(max-width: 767px){
                font-size: 19px;
            }
        }
        .items {
            width: 100%;
            .item_image {
                width: 120px;
                padding-right: 20px;
                img {
                    width: 100%;
                }
            }
        }
    }
    .milestone_range {
        .amounts {
            margin-bottom: 20px;
            font-size: 32px;
            color: $primarycolor;
            @include koara-bold;
            display: flex;
            justify-content: space-between;
            @media(max-width: 991px){
                font-size: 24px;
                margin-bottom: 10px;
            }
        }
        .bar {
            margin-bottom: 20px;
            input {
                width: 100%;
                -webkit-appearance: none;
                appearance: none;
                background: transparent;
                cursor: pointer;
                &::-webkit-slider-runnable-track {
                    height: 10px;
                    background: $highlightcolor;
                    border-radius: 5px;
                    width: 100%;
                }
                &::-moz-range-track {
                    height: 10px;
                    background: $highlightcolor;
                    border-radius: 5px;
                    width: 100%;
                }
                &::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    appearance: none;
                    background: $primarycolor;
                    width: 22px;
                    height: 22px;
                    border-radius: 11px;
                    margin-top: -6px;
                }
                &::-moz-range-thumb {
                    -webkit-appearance: none;
                    appearance: none;
                    background: $primarycolor;
                    width: 22px;
                    height: 22px;
                    border-radius: 11px;
                    margin-top: -6px;
                }
            }
        }
    }

    .milestone_thumbs {
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: 20px;
        .milestone_thumb {
            width: 100%;
            height: 100%;
            aspect-ratio: 1;
            position: relative;
            background-repeat: no-repeat;
            background-size: cover;
            .overlay {
                @include overlay;
                background-color: rgba(0, 0, 0, 0.6);
            }
            .contents {
                position: relative;
                height: 100%;
                .title, .amount {
                    position: absolute;
                    top: 15px;
                    left: 15px;
                    color: $highlightcolor;
                    font-size: 24px;
                    @media(max-width: 991px){
                        font-size: 11px;
                        top: 8px;
                        left: 8px;
                    }
                    @media(max-width: 767px){
                        font-size: 10px;
                    }
                    @media(max-width: 400px){
                        font-size: 8px;
                    }
                    &.amount {
                        top: auto;
                        bottom: 15px;
                        @media(max-width: 991px){
                            bottom: 8px;
                        }
                    }
                }
            }
        }
    }

    .milestone_content {
        margin-bottom: 30px;
        .milestone_main {
            display: flex;
            justify-content: space-between;
            align-items: center;
            column-gap: 30px;
            margin-bottom: 20px;
            @media(max-width: 991px){
                margin-bottom: 15px;
            }
            @media(max-width: 600px){
                flex-direction: column;
                row-gap: 30px;
                margin-bottom: 0;
            }
            .milestone_image, .milestone_top_content {
                width: calc(50% - 15px);
                @media(max-width: 767px){
                    width: 100%;
                }
                &.wide {
                    width: 100%;
                }
                img {
                    width: 100%;
                }
            }
        }
        .milestone_bottom {

        }
    }

}

#supporter_dashboard {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 20px;
    row-gap: 20px;
    @media(max-width: 991px){
        row-gap: 10px;
        column-gap: 6px;
    }
    @media(max-width: 767px){
        row-gap: 16px;
    }
    .dashboard_block {
        border-radius: 15px;
        color: $quarternarycolor;
        width: 100%;
        padding: 30px 20px;
        @media(max-width: 991px){
            padding: 18px;
        }
        @media(max-width: 767px){
            padding: 30px;
        }
        &.primary {
            background-color: $primarycolor;
        }
        &.secondary {
            background-color: $secondarycolor;
        }
        &.tertiary {
            background-color: $tertiarycolor;
        }
        &.highlight {
            background-color: $highlightcolor;
        }
        &.quarternary {
            background-color: #FBF2E8;
            color: $primarycolor;
            h2 {
                color: $primarycolor;
            }
        }
        &.third {
            width: calc(33.33% - 14px);
            @media(max-width: 991px){
                width: calc(33.33% - 5px);
            }
            @media(max-width: 767px){
                width: 100%;
            }
        }
        &.half {
            width: calc(50% - 10px);
            @media(max-width: 991px){
                width: 100%;
            }
        }
        h2 {
            color: $quarternarycolor;
            @media(max-width: 991px){
                font-size: 16px;
            }
            @media(max-width: 767px){
                font-size: 24px;
            }
        }
        p {
            font-size: 17px;
            margin: 0;
            @media(max-width: 991px){
                font-size: 12px;
            }
            @media(max-width: 767px){
                font-size: 15px;
            }
            a {
                color: $quarternarycolor;
            }
        }
        .button {
            margin: 10px 0 0;
            @media(max-width: 991px){
                text-align: right;
            }
            .btn {
                padding: 11px 26px;
                font-size: 24px;
                @media(max-width: 991px){
                    font-size: 14px;
                    padding: 6px 8px;
                }
            }
        }
    }
}

#donate_section {
    padding: 160px 0 40px;
    @media(max-width: 991px){
        padding-top: 180px;
    }
    h1 {
        color: $quarternarycolor;
        text-align: center;
        margin-bottom: 60px;
    }
}

#donation_success_block {
    @media(max-width: 767px){
        text-align: center;
    }
    .block_subtitle {
        @include koara;
        font-size: 28px;
        margin-bottom: 64px;
        @media(max-width: 991px){
            font-size: 18px;
            margin-bottom: 20px;
            line-height: 1.2em;
        }
        @media(max-width: 767px){
            font-size: 20px;
            margin-bottom: 30px;
        }
    }
    .block_link a {
        font-size: 24px;
        padding: 11px 16px;
        @media(max-width: 991px){
            font-size: 11px;
            padding: 7px 14px;
        }
        @media(max-width: 767px){
            font-size: 14px;
            padding: 8px 28x;
        }
    }
    .block {
        @media(max-width: 767px){
            aspect-ratio: unset;
            padding: 0 0 30px;
        }
    }
    .image_block {
        @media(max-width: 767px){
            aspect-ratio: 1;
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;
        }
    }
}

#furniture_page_content {
    padding-bottom: 40px;
}

#furniture_collection_form {
    padding-bottom: 40px;
}

#photo_listing {
    width: 100%;
    .photo_item {
        margin-bottom: 20px;
    }
    .application_field {
        label {
            display: none;
        }
        .row {
            display: none;
        }
        .form-control.is-valid {
            background: none;
            border-color: #ced4da;
            padding: .375rem .75rem;
        }
    }
}

#furniture_page_calendar_intro {
    padding: 30px 0 20px;
}

#furniture_collection_calendar {
    padding-bottom: 40px;
    .calendar_wrap {
        .title {
            background: $primarycolor;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 18px 20px;
            .title_text {
                font-size: 22px;
                color: $quarternarycolor;
                font-weight: bold;
            }
            .month_link {
                font-size: 22px;
                line-height: 1em;
                color: $quarternarycolor;
            }
        }
        .weekdays {
            display: flex;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            column-gap: 8px;
            padding: 30px 0 12px;
            .weekday {
                width: calc(100% / 7 - 7px);
                text-align: center;
                padding: 0 0 24px 0;
                font-size: 16px;
                color: $primarycolor;
                border-bottom: 2px solid $primarycolor;
                @include koara-bold;
                padding-bottom: 14px;
            }
        }
        .calendar_detail {
            display: flex;
            flex-wrap: wrap;
            column-gap: 8px;
            row-gap: 8px;
            .calendar_day {
                width: calc(100% / 7 - 7px);
                background: $primarycolor;
                aspect-ratio: 1.2;
                padding: 10px 8px;
                border-radius: 8px;
                text-align: right;
                margin: 0;
                display: block;
                &.disabled {
                    background: linear-gradient(0deg, rgba(81, 27, 75, 0.5) 0%, rgba(142, 98, 137, 0.5) 100%);
                }
                @media(max-width: 767px){
                    aspect-ratio: auto;
                }
            }
            .number {
                text-align: right;
                color: $quarternarycolor;
                font-size: 16px;
                margin-bottom: 15px;
                font-weight: bold;
            }
            .availability {
                width: 100%;
                .btn {
                    width: 100%;
                    @media(max-width: 767px){
                        font-size: 12px;
                        padding: 0;
                    }
                    @media(max-width: 400px){
                        font-size: 10px;
                    }
                }
            }
        }
    }
}

#dayModal {
    .modal-dialog {
        @media(min-width: 676px){
            max-width: 600px;
        }
    }
    .modal-content {
        background: $quarternarycolor;
        padding: 20px;
        position: relative;
    }
    .modal-header {
        border-bottom: 1px solid $primarycolor;
        padding: 0 0 10px;
        .modal-title {
            color: $primarycolor;
            font-size: 18px;
            font-weight: bold;
        }
        .btn-close {
            position: absolute;
            top: 15px;
            right: 20px;
            color: $primarycolor;
        }
    }
    .modal-body {
        padding: 10px 0 20px;
        table {
            width: 100%;
            font-size: 15px;
            color: $primarycolor;
            border-collapse: separate;
            border-spacing: 0;
            margin: 0;
            tr {
                &:first-child {
                    td {
                        border-top: 1px solid $primarycolor;
                        &:first-child {
                            border-top-left-radius: 8px;
                        }
                        &:last-child {
                            border-top-right-radius: 8px;
                        }

                    }
                }
                &:last-child {
                    td {
                        &:first-child {
                            border-bottom-left-radius: 8px;
                        }
                        &:last-child {
                            border-bottom-right-radius: 8px;
                        }
                    }
                }
                &.available {
                    td {
                        background: $primarycolor;
                        color: $quarternarycolor;
                    }
                }

                td {
                    padding: 15px 10px;
                    @include koara-bold;
                    border-bottom: 1px solid $primarycolor;
                    &:first-child {
                        border-left: 1px solid $primarycolor;
                    }
                    &:last-child {
                        border-right: 1px solid $primarycolor;
                    }
                    &.book {
                        text-align: right;
                    }
                }
            }
        }
    }
    .modal-footer {
        border: 0;
        padding: 0;
    }
}

#confirmation_page {
    padding: 150px 0 50px;
    .confirm_form {
        padding-top: 30px;
        .btn {
            font-size: 24px;
            padding: 15px 16px;
        }
    }
}

#shop_categories {
    padding: 40px 0;
    @media(max-width: 991px){
        padding: 30px 0;
    }
    .all_products {
        padding-top: 20px;
        text-align: center;
        a {
            padding: 15px 16px;
            font-size: 18px;
            @media(max-width: 991px){
                padding: 11px 16px;
            }
            @media(max-width: 767px){
                padding: 8px 27px;
                font-size: 14px;
            }
        }
    }
}


#shop_filters {
    padding-top: 30px;
}

#products_listing {
    &::before {
        content: "";
        display: block;
        height: 103px;
        margin: -103px 0 0;
    }
}

#product_detail {
    padding-bottom: 40px;
    .back {
        padding: 30px 0 20px;
        margin: 0;
        font-size: 16px;
    }
    .detail_wrapper {
        display: flex;
        justify-content: space-between;
        @media(max-width: 767px){
            flex-direction: column;
            row-gap: 20px;
        }
        .product_images {
            width: 680px;
            @media(max-width: 1399px){
                width: 556px;
            }
            @media(max-width: 1199px){
                width: 466px;
            }
            @media(max-width: 991px){
                width: 352px;
            }
            @media(max-width: 767px){
                width: 100%;
            }
            .main_image {
                margin-bottom: 10px;
                img {
                    border-radius: 30px;
                    width: 100%;
                }
            }
            .main_image {
                img {
                    width: 100%;
                }
            }
            .sub_images {
                display: flex;
                justify-content: left;
                flex-wrap: wrap;
                margin: 0 -5px;
                row-gap: 10px;
                .sub_image {
                    width: 20%;
                    padding: 0 5px;
                    img {
                        margin-right: 10px;
                        width: 100%;
                        border-radius: 10px;
                    }
                }
            }
        }
        .product_content {
            width: 656px;
            font-size: 24px;
            @media(max-width: 1399px){
                width: 520px;
            }
            @media(max-width: 1199px){
                width: 430px;
            }
            @media(max-width: 991px){
                width: 327px;
                font-size: 20px;
            }
            @media(max-width: 767px){
                width: 100%;
            }
            h1 {
                font-size: 48px;
                margin-bottom: 20px;
                @media(max-width: 991px){
                    font-size: 24px;
                    margin-bottom: 10px;
                }
            }
            .product_price {
                font-size: 28px;
                margin-bottom: 20px;
                @media(max-width: 991px){
                    font-size: 20px;
                    margin-bottom: 10px;
                }
            }
        }

        .cart_button {
            .btn {
                font-size: 24px;
                padding: 15px 16px;
                @media(max-width: 991px){
                    padding: 11px 16px;
                }
            }
        }
    }
}

.product_quantity {
    display: flex;
    justify-content: left;
    font-size: 28px;
    align-items: center;
    margin-bottom: 16px;
    @media(max-width: 991px){
        font-size: 23px;
        flex-wrap: wrap;
    }
    label {
        color: $primarycolor;
        margin-right: 16px;
        font-weight: bold;
        @media(max-width: 991px){
            width: 100%;
        }
    }
    .quantity_changer {
        width: 25px;
        text-align: center;
        a {
            color: $primarycolor;
        }
    }
    .quantity_value {
        color: $secondarycolor;
        width: 50px;
        text-align: center;
    }
}

#cart {
    padding: 150px 0 40px;
    @media(max-width: 767px){
        padding: 108px 0 30px;
    }

    .cart_header {
        padding: 50px 0 70px;
        @media(max-width: 991px){
            padding: 35px 0 40px;
        }
        @media(max-width: 767px){
            padding: 50px 0 30px;
        }
        h1 {
            font-size: 48px;
            margin-bottom: 20px;
            @media(max-width: 767px){
                font-size: 28px;
            }
        }
        p {
            font-size: 20px;
        }
    }

    p {
        a {
            text-decoration: underline;
        }
    }

    .cart_details {
        display: flex;
        justify-content: space-between;
        @media(max-width: 767px){
            flex-direction: column;
            row-gap: 50px;
        }
        h2 {
            font-size: 28px;
            margin-bottom: 20px;
            @media(max-width: 991px){
                font-size: 20px;
            }
        }
        .cart_items {
            width: 520px;
            display: flex;
            flex-direction: column;
            @media(max-width: 991px){
                width: 375px;
            }
            @media(max-width: 767px){
                width: 100%;
            }
            .cart_item {
                display: flex;
                justify-content: left;
                column-gap: 25px;
                margin-bottom: 40px;
                @media(max-width: 991px){
                    column-gap: 18px;
                    margin-bottom: 25px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                .cart_item_image {
                    img {
                        width: 133px;
                        height: auto;
                        @media(max-width: 991px){
                            width: 100px;
                        }
                    }
                }
                .cart_item_details {
                    h2 {
                        font-size: 24px;
                        margin-bottom: 20px;
                        @media(max-width: 991px){
                            font-size: 15px;
                            margin-bottom: 12px;
                        }
                    }
                    .product_quantity {
                        font-size: 20px;
                        @media(max-width: 991px){
                            font-size: 14px;
                        }
                        .quantity_value {
                            width: 32px;
                            @media(max-width: 991px){
                                width: 24px;
                            }
                        }
                        label {
                            width: auto;
                        }
                    }
                    .cart_item_price {
                        font-size: 20px;
                        @media(max-width: 991px){
                            font-size: 12px;
                        }
                    }
                }
                .cart_item_remove {
                    margin-left: auto;
                    a {
                        color: $secondarycolor;
                    }
                }
            }
        }
        .cart_summary {
            width: 330px;
            font-size: 16px;
            @media(max-width: 991px){
                width: 245px;
            }
            @media(max-width: 767px){
                width: auto;
                max-width: 280px;
            }
            .promocode_form {
                padding-bottom: 25px;
                .field_wrapper {
                    border-bottom: 1px solid $primarycolor;
                    padding-bottom: 20px;
                    @media(max-width: 991px){
                        padding-bottom: 16px;
                    }
                    label {
                        display: none;
                    }
                    input {
                        font-size: 22px;
                        background: none;
                        border: none;
                        border-radius: 0;
                        padding: 0;
                        @media(max-width: 991px){
                            font-size: 15px;
                        }
                    }
                    margin-bottom: 12px;
                }
                .promo_submit {
                    text-align: right;
                    .btn {
                        padding: 11px 10px;
                        @media(max-width: 991px){
                            padding: 8px 10px;
                        }
                    }
                }
            }
            .cart_totals {
                display: flex;
                flex-direction: column;
                row-gap: 3px;
                margin-bottom: 26px;
                @media(max-width: 991px){
                    margin-bottom: 22px;
                }
                .cart_total_item {
                    display: flex;
                    justify-content: left;
                    font-size: 16px;
                    @media(max-width: 991px){
                        font-size: 12px;
                    }
                    .cart_total_item_name {
                        width: 60%;
                        a {
                            color: #DF1C36;
                        }
                    }
                    .cart_total_item_value {
                        width: 40%;
                        text-align: right;
                    }
                }
            }
            hr {
                margin:  0 0 10px 0;
                border-top: 1px solid $primarycolor;
                opacity: 1;
            }
            .proceed {
                .btn {
                    font-size: 16px;
                    padding: 9px 12px;
                }
            }
            .cart_back {
                padding-top: 16px;
                font-size: 16px;
                @media(max-width: 991px){
                    font-size: 12px;
                }
                a {
                    text-decoration: underline;
                }
            }
        }
    }

    .fields {
        padding-bottom: 20px;
    }
    
    .addresses {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        margin-bottom: 20px;
        .address {
            width: 200px;
            background: #fff;
            border: 2px solid #fff;
            margin: 0 10px 10px 0;
            padding: 5px;
            font-size: 14px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            cursor: pointer;
            @media(max-width: 899px){
                width: 150px;
                font-size: 12px;
            }
            @media(max-width: 767px){
                font-size: 10px;
            }
            &.selected {
                border: 2px solid $primarycolor;
            }
        }
    }

    .new_address {
        display: none;
    }

    .delivery_options, .payment_methods {
        .delivery_option, .payment_method {
            font-size: 14px;
            margin-bottom: 10px;
            label {
                font-size: 16px;
                font-weight: bold;
                .price {
                    font-weight: bold;
                    color: $primarycolor;
                }
            }
            p {
                margin: 0;
            }
        }
    }

    .declaration {
        display: flex;
        justify-content: space-between;
        vertical-align: center;
        row-gap: 20px;
        @media(max-width: 767px){
            flex-wrap: wrap;
            justify-content: center;
        }
        .fields {
            padding: 0;
        }
        .terms {
            background: $primarycolor;
            border-radius: 10px;
            width: 800px;
            padding: 16px 15px;
            color: $quarternarycolor;
            font-weight: normal;
            font-size: 16px;
            @media(max-width: 991px){
                width: 600px;
            }
            @media(max-width: 767px){
                width: 100%;
            }
            input {
                border-color: $quarternarycolor;
                background-color: $primarycolor;
                font-size: 16px;
                &:checked {
                    background-color: $primarycolor;
                }
            }
            label {
                font-weight: normal;
                a {
                    color: $tertiarycolor;
                    font-weight: bold;
                }
            }
        }
        .submit {
            width: 100px;
            text-align: center;
        }
    }

    .loader {
        width: 50px;
    }
}

#purple_section.order_details {
    color: $quarternarycolor;
    font-size: 22px;
    @media(max-width: 991px){
        font-size: 21px;
    }
    @media(max-width: 767px){
        font-size: 19px;
    }
    h1 {
        @media(max-width: 991px){
            font-size: 43px;
            margin-bottom: 30px;
        }
        @media(max-width: 767px){
            font-size: 29px;
            margin-bottom: 10px;
        }
    }
    .order_number {
        font-size: 32px;
        margin-bottom: 26px;
        @media(max-width: 991px){
            font-size: 29px;
            margin-bottom: 24px;
        }
        @media(max-width: 767px){
            font-size: 19px;
            margin-bottom: 19px;
        }
    }
    .order_address {
        line-height: 1.6em;
    }
    strong {
        color: $quarternarycolor;
    }
    .order_summary {
        padding-bottom: 24px;
        font-size: 22px;
        @media(max-width: 991px){
            font-size: 19px;
        }
        @media(max-width: 767px){
            font-size: 14px;
        }   
        .order_summary_item {
            display: flex;
            justify-content: space-between;
            .cart_total_item_name {
                width: 50%;
            }
            .cart_total_item_value {
                width: 50%;
                text-align: right;
            }
        }
    }
    .back_button {
        .btn {
            font-size: 24px;
            border-radius: 30px;
            padding: 11px 27px;
        }
    }
}

.lottery_terms {
    font-size: 14px;
    strong, a {
        font-weight: bold;
    }
}